@import "../../../../variables";

.adjacencies-menu {

  padding-right: 0 !important;
  margin-left: -$menu-content-padding;
  margin-bottom: -$menu-content-padding;
  margin-top: -$menu-content-padding;

  .adjacencies-menu-add {
    color: $secondary-color;
  }

  li:last-child {
    border-bottom: none;
  }

}