@import "src/variables";

.room-category-dialog {

  .room-category-dialog-inner {

    .room-category-name {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    .toggle-switch {
      margin-top: 2rem;
      padding: 1rem;
    }

  }

}