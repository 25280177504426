@import "../../variables";

.list-menu-item {

  margin-left: 1.8rem;

  .list-menu-item-container {
    height: $list-items-height;

    border-bottom: solid 1px $inspector-border-color;
    box-sizing: border-box;

    padding-left: 1rem;

    display: flex;
    align-items: center;

    .list-menu-item-content {

      height: 100%;
      cursor: pointer;
      box-sizing: border-box;
      padding: -.9rem $menu-content-padding;

      flex-grow: 1;

      display: flex;
      align-items: center;

      text-transform: capitalize;

      &.bordered {
        border-left: $department-list-item-colored-border-size;
        border-left-style: solid;
        border-left-color: transparent;
      }

      .list-menu-item-icon {
        height: 2.4rem;
        width: 2.4rem;
        padding-right: 0.5rem;

        &.second {
          padding-right: 1rem;
          padding-top: .3rem;
        }
      }

      .list-menu-item-arrow {
        height: 2.4rem;
        width: 2.4rem;
        float: right;
      }

      &.disabled {
        color: $disabled-font-color !important;
        background-color: transparent !important;
        cursor: unset;
      }
    }

    .list-menu-add-item {
      margin-left: -.9rem - 1rem - 1.8rem;
      justify-content: center;

      .text-wrapper {
        width: auto;
      }
    }
  }

}

