@import "../../../../variables";

.data-field {

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: $menu-content-padding;
  margin-bottom: $menu-content-padding;

  width: 100%;
  height: 3rem;
}
