
.optimize-button {
  justify-content: center !important;

  .icon-wrapper {
    svg {
      height: 3rem;
      width: 3rem;
      padding-right: 0 !important;
    }
  }

  .button-inner {
    padding-left: .4rem;
  }
}