@import "../../../variables";

.application-builder-overview {

  .content {
    display: flex;
    text-transform: capitalize;
  }

  .wall {
    fill: #000000;
    stroke: #000000;
  }

  .door {
    fill: #ffffff;
    stroke: #ffffff;
  }

  .button {
    margin-right: 0 !important;
  }

  //fix for padding in button component
  .button-component.with-icon svg {
    padding-right: 0;
  }
  .button-component .button-inner {
    padding-left: 0.6rem;
  }

  .furniture-sets-category-tree-list {
    margin-left: -1.2rem;
  }
}