@import "../../variables";

$chip-border-color: rgb(217, 218, 221);
$chip-border-disabled-color: lighten($chip-border-color, 10%);

.chip {

  display: inline-flex;

  .chip-icon {

    display: flex;
    align-items: center;
    justify-content: center;

    height: 3rem;
    width: 2.4rem;

    box-sizing: border-box;

    border-radius: 0 $button-radius-greater $button-radius-greater 0;
    border: solid 0.1rem $chip-border-color;

    padding: 0;

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  label {
    display: flex;
    align-items: center;

    padding: 0 0.9rem;
    height: 3rem;

    margin-bottom: 0;

    box-sizing: border-box;
    border-radius: $button-radius-greater;
    border: solid 0.1rem $chip-border-color;

    letter-spacing: 0.024rem;

    &.with-icon {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: none;
    }
  }

  &:focus-within {
    outline: 0.2rem solid $outline-color;
    outline-offset: 0.1rem;
  }

  input:hover + label, .chip-icon:hover {
    background-color: $secondary-background-color;
  }


  input:disabled {
    & + label, & ~ .chip-icon {
      border-color: $chip-border-disabled-color !important;
      color: $disabled-font-color !important;
      background-color: $disabled-background-color !important;
      cursor: default;
    }
  }

  input:checked {
    & + label, & ~ .chip-icon {
      border-color: $primary-color;
      background-color: $primary-color;
      color: $font-color-inverted;
    }

    & ~ .chip-icon {
      border-left-color: rgb(1, 55, 80);;
    }

    &:hover {
      & + label, & ~ .chip-icon {
        background-color: $primary-color-light;
      }
    }

  }

  input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

}