@import "../variables";

.settings-content {

  .settings-menu-nav-item-active {
    font-weight: $font-weight-medium;
  }

  .content {
    width: 100%;
  }
}