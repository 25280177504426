@import "src/variables";

.drawing-editor-upload-placeholder {

  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(100vh - #{$header-height});

  .prepare-floorplan-container {
    width: 27.2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    opacity: 50%;
  }


  &.onboarding {
    height: calc(100vh - #{$title-bar-height} - #{$suite-toolbar-height} - #{$drawing-navigation-height});

  }
}