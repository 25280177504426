@import "src/variables";

.drawing-tool-menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-bottom: solid .1rem #e8e9eb;

  .tool-group-with-divider{
    display: flex;
    flex-direction: row;
  }
  .tool-divider {
    margin-top: 10px;
  }
  .tool-group {
    display: flex;
    flex-direction: column;
  }
  .tool-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    gap: 8px;
    border-right: solid .1rem #e8e9eb;
    padding-right: 11px;
    padding-left: 11px;

    .tool-icon-button {
      width: 32px;
      height: 32px;

      &.selected {
        background-color: #e5e8ea;
      }
    }
  }

  .tool-area-label-container {
    margin-top: 13px;
    margin-bottom: -4px;
    font-size: 13px;
    height: 13px;
    width: 80%;
    display: inline-block;
    padding-right: 11px;
    padding-left: 11px;
    text-align: center;
  }

  .tool-area-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    //height: flex;
  }

  .tool-area-icon-wrapper {
    display: flex;
    gap: 8px;

    .tool-icon-button-info {
      height: 32px;
      width: 32px;
    }
  }

  .buildings-tool-sidebar-help {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    border-top: solid .1rem #e8e9eb;
    margin-bottom: 4rem;

    .buildings-tool-sidebar-help-icon {
      width: 3.2rem;
      height: 3.2rem;

      margin-top: .6rem;

      &.active {
        background-color: $primary-color-transparent;
      }
    }
  }

  .upload-filled-button {
    background-color: $grey;
    min-width: 180px;
    height: 30px;
    border-radius: 2px;
    color: #B1B3B8;
  }
}