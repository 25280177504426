@import "../../../variables";

#application-builder-editor-plan {
  height: 100%;
  width: 100%;
}

.application-builder-editor {

  height: calc(100vh - #{$header-height});

  .main {
    display: flex;
  }

  .sidebar {
    padding: 0;
    height: calc(100vh - #{$header-height} - 5.5rem);

    &.left {
      width: 5.5rem;
    }

    &.right {
      width: $sidebar-width;
    }

  }

  .content {
    width: calc(100vw - #{$sidebar-width} - 5.5rem);
    overflow: hidden;
  }

  .undo_container {
    display: flex;
    margin-right: 2rem;
  }

  .app_builder_undo, .app_builder_redo {
    height: 5.4rem;
    width: 3rem;
    padding-top: 0.5rem;
  }
}