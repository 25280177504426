
.labeled-icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 8rem;
  width: 11.2rem;

  cursor: pointer;

  .icon-wrapper {
    height: 3.6rem;
    width: 3.6rem;
  }

  .text-wrapper {
    width: auto;
  }

  &.selected, &:hover {
    box-shadow: 0 .2rem .8rem 0 rgba(196, 198, 210, 0.4);
  }
}