
.import-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 14rem;
  height: 3rem;
  border: none !important;
  border-radius: .2rem;
  text-transform: lowercase;

  &:hover {
    background-color: #E7DFB7;
    width: 15rem;
    height: 3rem;
  }
}