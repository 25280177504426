@import "../../../variables";

.plan-app-container {

  height: calc(100vh - #{$header-height});
  display: flex;

  .floorplan-content,
  .edit-outline-content {
    width: calc(100vw - #{$sidebar-width});
    overflow: hidden;
  }

  .floors-content {
    //height: calc(100vh - #{$header-height});
    width: calc(100vw - 2 * #{$sidebar-width}) !important;
    overflow: hidden;
  }
}