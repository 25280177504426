@import "src/variables";

.add-delete-buttons {
  padding-left: .6rem;
}

.import-export-buttons {
  padding-right: .6rem;

  > button {
    width: 14rem;
  }
}

.discard-confirm-buttons {
  > button {
    width: 14rem;
  }

  border-left: {
    color: $primary-border-color;
    style: solid;
    width: 0.1rem
  };
  padding-left: 1.8rem;
  padding-right: .8rem;
}