.color-picker-input {

  .color-picker-expandable {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .color-preview {
      height: 1.7rem;
      width: 1.7rem;
      flex-shrink: 0;
      border-radius: 3px;
      cursor: pointer;
    }

    .expand-button {
      max-height: 1.6rem;
      max-width: 1.6rem;
    }
  }

  div > div > span {
    margin: 0.3rem !important;
    height: 2rem !important;
    width: 2rem !important;
    padding: 0.1rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
  }

  div > div > span:hover {
    outline: none !important;
    box-shadow: 0 0 0 1px #49516a inset;
  }

  div > div > span > div {
    height: 100% !important;
    width: 100% !important;

    display: flex;
    justify-content: center;
    align-items: center;
    outline: none !important;
    box-shadow: none !important;
  }

  div > div > span > div:hover {
    outline: none !important;
    box-shadow: none !important;
  }

  .color-picker {
    position: relative;
    top: 0.5rem;
    left: -1.4rem;

    .github-picker {
      width: 20rem !important;
      padding: 1.4rem !important;
      justify-content: center;
    }

    span > div > span > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.8rem !important;
      width: 1.8rem !important;
      border-radius: 0.3rem !important;
    }

    span > div > span > div:hover {
      outline: none !important;
      box-shadow: none !important;
    }

  }
}
