@import "../../../variables";

.settings-furniture {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: solid 0.1rem $fly-out-border-color;

  .furniture-settings-upper-section {

    .furniture-settings-section-1 {
      display: flex;
      flex-direction: column;

      .furniture-settings-text {
        margin-bottom: 2rem;
      }

      .furniture-settings-text:last-child {
        margin-bottom: 0;
        color: grey;
      }
    }

    .furniture-settings-section-2 {

      display: flex;
      flex-direction: column;

      .rotate-button {

        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;

        width: 3rem;
        height: 3rem;
        margin-top: 1rem;
        border: solid 0.1rem $disabled-color;
        border-radius: 0.3rem;

        svg {
          height: 3rem;
          width: 3rem;
        }

      }

    }
  }

  .furniture-settings-lower-section {
    display: flex;
    justify-content: center;

    .furniture-delete-button {
      margin-right: 0;
    }
  }
}