@import "../../../../variables";


.side-bar-title {
  display: flex;
  align-items: center;

  height: $list-items-height;

  padding-left: 1.6rem;

  background-color: $secondary-background-color;

  border-bottom: solid .1rem $inspector-border-color;


  .side-bar-title-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: .6rem;
    padding-bottom: .3rem;
  }

}

