@import "./../variables";

@mixin style-buttons {

  display: flex;
  flex-direction: row;

  .button-group:last-of-type:not(:first-of-type) {
    border-left: {
      color: $primary-border-color;
      style: solid;
      width: 0.1rem
    };
    padding-left: 1.8rem;
  }

  .button-group:first-of-type:not(:last-of-type) {
    padding-right: 0.8rem;
  }
}