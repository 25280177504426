@import "../../variables";

.editable-table {

  input {
    &[type="email"],
    &[type="search"],
    &[type="text"],
    &[type="tel"],
    &[type="url"],
    &[type="password"] {
      height: 2.4rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border-radius: 0;
    }
  }

  select {
    padding-left: 0;
    border: none;
  }
}