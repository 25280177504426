@import "../../variables";

$user-toolbar-separator-color: rgba(141, 142, 151, 0.14);
$user-toolbar-title-font-size: 1.8rem;
$user-toolbar-label-font-size: 1rem;
$user-toolbar-label-background-color: rgb(245, 129, 51);

.title-bar {

  height: $title-bar-height;
  background-color: $secondary-background-color;

  &.dark-mode {
    background-color: $secondary-background-color-dark-mode;
  }

  .application-header-content {
    height: 100%;
    padding-left: 1.8rem;
    display: flex;
    justify-content: space-between;

    > div {
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .application-header-content-logo {
      margin-right: 1.1rem;
      cursor: pointer;
    }

    .application-header-version-section {
      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 0 1.8rem 0.4rem;

      .version-title, .version-number {
        color: $font-color;

        &.dark-mode {
          color: $primary-color-dark-mode;
        }
      }

      &.dark-mode {
        color: $primary-color-dark-mode;
      }

      .version-title {
        text-align: right;
        text-transform: uppercase;
        font-size: calcFontSize(8.5);
        font-weight: 600;
        letter-spacing: 0.155rem;
        line-height: calcLineHeight(8.5, 1);
        margin-bottom: .2rem;

        span {
          vertical-align: calcVerticalAlign(8.5);
        }

      }

      .version-number {
        align-self: flex-end;

        text-transform: capitalize;
        font-size: calcFontSize(7.5);

        span {
          line-height: calcLineHeight(7.5, 1);
        }
      }

    }

  }
}


