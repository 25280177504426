@import "../../../variables";

.pattern-inspector {
  height: calc(100vh - #{$header-height} - #{$builder-nav-height});
  border-left: solid 1px $fly-out-border-color;
}

.menu-separator {
  width: calc(100% - 2 * #{$menu-content-padding});
  height: 0px;
  border-top: solid 1px $primary-border-color;
  margin-left: $menu-content-padding;
  margin-right: $menu-content-padding;
  padding: 0;
}