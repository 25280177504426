@import "../../variables";

.inspector-header {
  height: $inspector-header-height !important;
  padding: $inspector-header-padding $inspector-header-padding $inspector-header-padding-bottom;
  border-bottom: solid 1px $inspector-list-divider-color;
  overflow: hidden;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h3 {
    text-transform: capitalize;
    display: inline-block;
    max-width: calc(#{$sidebar-width} - (#{$inspector-header-padding} * 2) - 1px);
    margin-bottom: 0;

    font-weight: 600;
    font-family: $font-family-condensed;
    font-size: calcFontSize(20);
    letter-spacing: 0.06rem;
  }

  .inspector-header-edit-outline {
    height: 2.4rem;
    border-color: #00a2e1;
    color: #00a2e1;
    width: 100%;

    svg, g > * {
      color: #00a2e1;
      stroke: #00a2e1;
    }

    .icon-wrapper {
      height: 2rem;
      width: 2rem;
    }

    &:hover {
      background-color: #e6f6fc;
    }
  }
}

.expand-inspector-button {
  float: right;
}
