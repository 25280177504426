@import "src/variables";

ul {
  list-style-type: none;
}

.floor-tree-list-container {

  .floor-list-item {

    .floor-list-item-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      height: $list-items-height;

      padding-left: .8rem;

      border-bottom: solid .1rem $inspector-list-divider-color;

      .floor-list-item-text {
        display: flex;
        align-items: center;
        text-transform: capitalize;

        margin-left: -.6rem;

        width: 100%;
        height: 100%;

        .floor-list-icon {
          padding-bottom: .2rem;
        }
      }

      &.clickable {
        cursor: pointer;
      }

      &.active {
        background-color: $secondary-background-color;
      }
    }

    .floor-list-item-collapsible {

      margin-top: -$list-items-height;
      margin-left: .4rem;

      .floor-list-item-collapsible-trigger {
        display: flex;
        align-items: center;
        cursor: pointer;

        height: $list-items-height;
        width: $icon-button-dimensions;

        &.open {
          .trigger-arrow {
            transform: rotate(90deg);
          }
        }

        .trigger-arrow {
          margin-bottom: .5rem;
        }
      }
    }

    &.collapsible-floor-list-item {
      > .floor-list-item-content {
        padding-left: 3rem;
      }
    }

    .dropdown-menu {
      width: 100% !important;
    }
  }

}