@import "../../../variables";

.builder-overview-navBar {

  height: $builder-nav-height;
  width: 100%;
  background-color: #ffffff;
  border-bottom: solid 1px $fly-out-border-color;
  z-index: 2;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .builder-overview-navBar-content-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .builder-overview-text {
      font-weight: $font-weight-bold;
    }

    .builder-overview-navBar-icon {
      margin-left: 16px;
      margin-right: 16px;
    }

  }
}