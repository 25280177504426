@import "../../variables";

.text-wrapper {

  display: inline-block;
  width: 100%;
  height: calcSingleLineHeight($base-font-size, $base-line-height);

  &.multiline {
    width: auto;
    height: fit-content;

    line-height: calcLineHeight($base-font-size, $base-line-height + 0.5);

    h1 {
      line-height: calcLineHeight($base-heading-1-font-size, $base-heading-1-line-height + 0.5);
    }

    h3 {
      line-height: calcLineHeight($base-heading-3-font-size, $base-heading-3-line-height + 0.5);
    }

    h4 {
      line-height: calcLineHeight($base-heading-4-font-size, $base-heading-4-line-height + 0.5);
    }


    label {
      line-height: calcLineHeight($base-label-font-size, $base-label-line-height + 0.5);
    }
  }

  .text-inner {
    display: inline-block;

    padding-top: calcSingleLineHeight($base-font-size / 2.0, $base-line-height);

    width: 100%;
    height: 100%;

    white-space: nowrap;
    text-overflow: ellipsis;

    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;

    &.multiline {
      display: inline;
      width: auto;
      vertical-align: calcVerticalAlign($base-font-size);

      white-space: normal;
      overflow: unset;
      text-overflow: unset;
    }

    &.center {
      text-align: center;
    }

  }

  label {
    display: inline;
    margin-bottom: 0;
  }

  .paragraph, .link {
    padding-top: .7rem !important;
    letter-spacing: 0.03rem;
    font-size: 1.5rem;

    &.multiline {
      line-height: 1.8rem;
    }
  }

  .link {
    cursor: pointer;

    text-decoration: underline;
  }

  .paragraph-bold {
    letter-spacing: 0.03rem;
    padding-top: .7rem !important;
    font-size: 1.5rem;
    font-weight: 500;

    &.multiline {
      line-height: 1.8rem;
    }
  }

  .running-title {
    padding-top: .7rem !important;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.06rem;
  }

  .title, .number-title {
    margin-bottom: 0;
    padding-top: 1.3rem !important;

    font-weight: 600;
    font-family: $font-family-condensed;
    font-size: 3rem;
    letter-spacing: 0.06rem;
  }

  .number-title {
    font-size: 2.8rem;
  }

  .sub-title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.4rem;
  }

  .hero {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-top: 1.2rem;

    &.multiline {
      line-height: 3rem;
    }
  }

  .info-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: #00293b;
  }

  .info-paragraph {
    font-size: 1.1rem;
    font-weight: 400;
    color: #00293b;

    &.multiline {
      line-height: .4rem !important;
    }
  }

  &.dark-mode {
    color: white;
  }

}