@import "src/variables";

$width: 15.4rem;
$top: calc(#{$suite-toolbar-height} + #{$title-bar-height} + #{$drawing-navigation-height} + 7.4rem);

.drawing-help {

  position: absolute;

  top: $top;
  left: calc(#{$sidebar-width} + #{$tool-sidebar-width} + .2rem);

  width: 24.6rem;
  height: 25.9rem;

  background-color: #fff;

  box-shadow: 0 0.2rem .8rem rgba(0, 0, 0, .15);

  z-index: 3;

  .drawing-help-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      padding: .3rem .3rem 1rem .3rem;
      width: 24rem;
      height: 13.4rem;
    }

    .drawing-help-content-info {
      display: flex;
      flex-direction: column;
      width: 21rem;

      margin: 0 $dialog-window-padding $dialog-window-padding $dialog-window-padding;

      :first-child {
        margin-bottom: 1rem;
      }
    }

  }

  &.onboarding {
    left: calc(#{$sidebar-width} + .2rem);


    &.pan,
    &.scale,
    &.rectangle,
    &.door,
    &.zone,
    &.axes,
    &.polygon,
    &.window,
    &.team,
    &.axes_generate,
    &.pillar,
    &.infrastructure,
    &.axes_delete,
    &.blocked {
      //top: calc(#{$top} + 0.6rem) !important;
    }

    &.pan {
      left: calc(#{$onboarding-sidebar-width} + 2.0rem);
    }
    &.select{
      left:  calc(#{$onboarding-sidebar-width} + 2.0rem);
    }
    &.scale{
      left: calc(#{$onboarding-sidebar-width} + 12.0rem);
    }

    &.outline{
      left: calc(#{$onboarding-sidebar-width} + 23.0rem);
    }
    &.wall{
      left: calc(#{$onboarding-sidebar-width} + 23.0rem);
    }
    &.door{
      left: calc(#{$onboarding-sidebar-width} + 34.0rem);
    }
    &.window{
      left: calc(#{$onboarding-sidebar-width} + 34.0rem);
    }
    &.pillar{
      left: calc(#{$onboarding-sidebar-width} + 34.0rem);
    }

    &.markzone{
      left: calc(#{$onboarding-sidebar-width} + 48.0rem);
    }
    &.markroom{
      left: calc(#{$onboarding-sidebar-width} + 48.0rem);
    }
    &.markinfrastructure{
      left: calc(#{$onboarding-sidebar-width} + 48.0rem);
    }
    &.markservice{
      left: calc(#{$onboarding-sidebar-width} + 48.0rem);
    }
    &.markblocked{
      left: calc(#{$onboarding-sidebar-width} + 48.0rem);
    }

    &.axesautogenerate {
      left: calc(#{$onboarding-sidebar-width} + 71.0rem);
    }
    &.axesdelete {
      left: calc(#{$onboarding-sidebar-width} + 71.0rem);
    }
    &.axesedit {
      left: calc(#{$onboarding-sidebar-width} + 71.0rem);
    }
  }

}