@import "../../variables";

.radio-input-button-group {

  display: flex;
  justify-content: stretch;
  width: 100%;

  [type='radio'] {
    display: none;

    &:checked + label {
      background-color: $secondary-background-color;
      cursor: unset;
    }

    &:disabled + label {
      border-color: $disabled-color !important;
      color: $disabled-font-color !important;
      background-color: $disabled-background-color !important;
      cursor: default;
    }

    &:disabled:checked + label {
      background-color: $disabled-color-light !important;
    }

    & + label {
      flex: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      height: 3rem;
      padding: 0 1.8rem;

      background-color: $primary-background-color;
      border-top: 0.1rem solid $primary-color;
      border-bottom: 0.1rem solid $primary-color;
      border-left: 0.1rem solid $primary-color;
      box-sizing: border-box;

      text-align: center;
      text-transform: capitalize;
      text-decoration: none;

      cursor: pointer;

      &:hover {
        background-color: $secondary-background-color;
      }

      &:focus {
        outline: 0.2rem solid $outline-color;
        outline-offset: 0.1rem;
      }
    }

    &:last-of-type + label {
      border-top-right-radius: $button-radius;
      border-bottom-right-radius: $button-radius;
      border-right: 0.1rem solid $primary-color;
    }

    &:first-of-type + label {
      border-top-left-radius: $button-radius;
      border-bottom-left-radius: $button-radius;
    }


  }

}