@import "../../variables";

.legend-component {
  .legend-window {
    position: absolute;

    background-color: $primary-background-color;
    border-radius: 2px;
    box-shadow: 0 4px 13px 0 rgba(55, 59, 81, 0.25);
    line-height: normal;
    opacity: 0;
    pointer-events: none;

    .legend-content {

      position: relative;
      padding: 4rem;

      h3 {
        font-weight: 500;
        font-size: 1.5rem;
        letter-spacing: 0.32px;
        margin-bottom: 0;
      }

      h4 {
        color: $disabled-color;
        line-height: initial;
        margin-bottom: 0;
      }

      .legend-box {
        .dot {
          width: 1.2rem;
          height: 1.2rem;
          background-color: $disabled-color;
          float: left;
          margin-right: 1.2rem;
        }

        margin-bottom: .8rem;
      }

      .legend-icon {
        .icon {
          width: 2rem;
          height: 2rem;
          float: left;
          margin-right: 1.2rem;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;


          &.zone {
            background-image: url('../../assets/images/png/icon_zone.png');
          }

          &.room {
            background-image: url('../../assets/images/png/icon_room.png');
          }

          &.infra {
            background-image: url('../../assets/images/png/icon_infra.png');
          }

          &.service {
            background-image: url('../../assets/images/png/icon_service.png');
          }


        }

        margin-bottom: 1.2rem;
        margin-top: .8rem;
        font-size: 1.5rem;

        h3 {
          padding-top: 0.35rem;
          margin-left: 3.8rem;
        }

        h4 {
          margin-left: 3.8rem;
        }


      }

    }


    .close-legend {
      position: absolute;
      right: 1.2rem;
      top: 1.2rem;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url("../../assets/images/svg/legend_close.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    &.opened {

      opacity: 1;
      pointer-events: auto;


    }

    &.collapsed {


    }
  }

  .open-button {
    position: absolute;
    background-color: $primary-background-color;
    background-image: url("../../assets/images/svg/legend_help.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.8rem;
    cursor: pointer;
    padding: 0;
    width: 3.6rem;
    height: 3.6rem;
    left: auto;
    top: auto;
    right: .8rem;
    bottom: .8rem;
    box-shadow: 0 4px 13px 0 rgba(55, 59, 81, 0.25);

    &.dashboard-bar {
      right: 5.6rem;

      &.open {
        right: 18.8rem;
      }
    }

    &.onBoarding {
      right: 1.8rem !important;
      bottom: 8.8rem !important;
    }


  }
}

