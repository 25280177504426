@import "../../variables";

.dialog-background {

  background: fade_out($primary-color, 0.8);

  position: fixed;
  left: 0;
  right: 0;
  top: $suite-toolbar-height;
  bottom: 0;
  z-index: 10;

  .dialog-container {

    position: absolute;
    left: 50%;
    top: 50%;

  }
}

// Not inside of the component root css class to be able to override dialog styles for custom dialogs
.dialog-window {

  background: $primary-background-color;
  box-sizing: border-box;
  border-radius: 0.2rem;

  box-shadow: -2px 4px 15px 0px rgba(0, 0, 0, 0.43);

  transform: translateX(-50%) translateY(-50%);

  .dialog-window-content {

    width: 40rem;
    min-height: 20rem;
    height: 100%;

    display: flex;
    flex-direction: column;

    .dialog-headline {

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: $dialog-window-padding;
      box-sizing: border-box;
      height: 4.8rem;

      font-weight: 500;

      border-bottom: solid 0.1rem $primary-border-color;

      .dialog-close-icon {
        height: 2.4rem;
        width: 2.4rem;
      }

    }

    .dialog-subtitle {

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: $dialog-window-padding;
      box-sizing: border-box;
      height: 4.8rem;

      font-weight: 400;

      border-bottom: solid 0.1rem $primary-border-color;
    }

    .dialog-body {

      flex: auto;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding: $dialog-window-padding;

      .dialog-multiline-text:not(:first-child) {
        margin-top: 0.4rem;
      }

    }

    .dialog-content {
      flex: auto;

      &:not(:first-child) {
        margin-top: 2.4rem;
      }

      input,
      select,
      textarea {
        width: 100%;
      }
    }

    .dialog-button-group {

      flex-grow: 0;
      margin-top: 4rem;
      display: flex;
      justify-content: flex-end;

      > button {
        margin-left: 1.2rem;
        margin-right: 0;
      }

    }

  }

}
