@import "../../variables";

.navigation-menu {

  padding-left: 1rem;

  &.dark-mode {
    background-color: $third-background-color-dark-mode;
    color: white;

    > li:nth-child(1) {
      border-right: solid .1rem $dark-mode-border-separator;
      padding-right: .5rem;
    }
  }

  .navigation-menu-item {
    height: 100%;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    cursor: pointer;

    svg {
      height: $icon-button-dimensions;
      width: $icon-button-dimensions;
      margin-right: -0.6rem;
    }

    &.toolbar-nav-active, &:hover {
      .navigation-menu-item-text-wrapper {
        font-weight: $font-weight-medium;
        border-bottom: solid 0.2rem $primary-color;

        .navigation-menu-item-text {
          margin-bottom: -0.2rem;
        }

        &.dark-mode {
          border-color: white;
        }
      }
    }
  }

  > li:nth-child(1) {
    border-right: solid .1rem #013750;
    padding-right: .5rem;
  }
}