@import "../../../../variables";

.blocking {

  width: $sidebar-width;

  border-left: solid .1rem $inspector-border-color;


  .blocking-building-tree-list-container {
    padding-left: 1.5rem;
  }
}