@import "../../../../variables";

.applications-list {

  margin-bottom: -$menu-content-padding;

  .application-category-item {

    padding-bottom: 0;

    .application-category-item-title {
      margin-bottom: 0;
    }
  }

  .application-list-item {

    display: flex;
    margin-right: -$menu-content-padding;

    .application-description {

      display: flex;
      flex-direction: column;

      width: 100%;

      padding-top: $menu-content-padding;
      padding-bottom: $menu-content-padding;
      padding-right: $menu-content-padding;
      border-bottom: solid 1px $primary-border-color;

      > div {
        margin-bottom: 1rem;
      }

    }

    .ensemble-plan {
      height: 5.5rem;
      margin-right: $menu-content-padding;
      padding-top: $menu-content-padding;
      padding-bottom: $menu-content-padding;
    }

    polygon, polyline {
      stroke: #000000;
      fill: #ffffff;
      stroke-width: 1;
    }

    &:last-child {
      .application-description {
        border-bottom: 0;
      }
    }

  }

}