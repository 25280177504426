@import "../../variables";

.inactive-project-error-dialog {

  border-top: solid 0.2rem $error-color;

  .inactive-project-error-dialog-inner {
    width: 50rem;
  }

  .inactive-project-error-dialog-title {
    color: $error-color;
  }

  .inactive-project-error-dialog-content {

    display: flex;
    flex-direction: column;

    .error-dialog-message {

      color: $error-color;
      padding-bottom: 2.4rem;
      padding-right: $menu-content-padding;
      margin-right: -$menu-content-padding;

      border-bottom: solid 0.1rem $primary-border-color;

    }

    .projects-list {

      display: flex;
      flex-direction: column;

      padding-top: $menu-content-padding;

      .project {

        display: flex;
        flex-direction: column;

        padding-top: 3rem;

        .project-description {
          display: flex;
          flex-direction: column;

          .project-description-title-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .project-description-title-container {
              display: flex;
              flex-direction: column;

              padding-right: $menu-content-padding;

            }
          }

          .project-description-name {
            font-weight: $font-weight-medium;
          }

          .project-description-client {
            margin-top: 0.5rem;
          }

          .project-description-date {
            padding-top: 0.8rem;
            box-sizing: border-box;
            color: $secondary-border-color
          }

        }

      }

    }

  }

}