.info-image-popper {


  background-color: #ffffff;

  box-shadow: 0 0.4rem 1.3rem 0 rgba(55, 59, 81, 0.25);
  padding: 4.3rem 4rem;

  .info-image-title {
    font-weight: 500;
  }

  .info-image {
    display: flex;
    align-items: center;

    margin-left: 1.7rem;
    margin-right: 1.7rem;
    margin-top: 3rem;

  }

  .info-image-legend {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.6rem;

    .info-legend {
      margin-top: 1.2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .info-legend-color {
        width: 1.2rem;
        height: 1.2rem;
        opacity: 0.4;
        margin-right: 1.2rem;

        &.working-depth {
          background-color: #f5854e;
        }

        &.walking-distance {
          background-color: #60bf82;
        }

        &.walking-distance-back-to-back {
          background-color: #30a2dc;
        }

        &.clearance-to-facade {
          background-color: #FFD32D;
        }

        &.corridor-width {
          background-color: #805DFF;
        }
      }
    }
  }
}