// Mixins
@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}

// Custom variables
//––––––––––––––––––––––––––––––––––––––––––––––––––


// Colors
$active-background-color: rgb(205, 208, 214);
$inactive-background-color: rgba(57, 61, 83, 0.32);

$inspector-background-color: rgb(247, 247, 247);
$inspector-border-color: rgba(208, 209, 212, 0.5);
$inspector-list-divider-color: rgba(0, 0, 0, 0.1);

$fly-out-background-color: rgb(247, 247, 247);
$fly-out-border-color: rgb(208, 209, 212);

$inspector-menu-border-color: rgb(208, 209, 212);
$menu-separator-color: rgb(57, 101, 125);
$menu-separator-color: rgba(208, 209, 212, 0.53);

$input-border-color: rgb(230, 231, 232);

$lens-tool-color: rgb(238, 58, 68);

$primary-color: rgb(0, 41, 59);
$primary-color-transparent: rgba(0, 41, 59, 0.08);
$primary-color-light: rgb(30, 61, 80);
$secondary-color: rgb(245, 130, 51);

$primary-color-dark-mode: rgb(255, 255, 255);
$dark-mode-border-separator: #39657d;

$error-color: rgb(218, 0, 28);
$warn-color: $secondary-color;
$info-color: rgb(0, 161, 226);
$success-color: rgb(54, 211, 153);
$active-color: #96c47a;

$primary-background-color: rgb(255, 255, 255);
$secondary-background-color: rgb(245, 244, 249);
$third-background-color: rgb(248, 248, 248);
$light-background-color: rgb(246, 247, 249);

$secondary-background-color-dark-mode: rgb(0, 50, 72);
$third-background-color-dark-mode: rgb(0, 64, 92);

$primary-border-color: rgb(232, 233, 235);
$secondary-border-color: rgb(177, 179, 184);
$outline-color: rgba(56, 74, 174, 0.3);
$orange-border-color: rgb(218, 91, 43);

$corporate-color: rgb(138, 250, 128);
$floors-color: rgb(252, 126, 27);
$settings-color: rgb(34, 160, 219);
$blocking-color: rgb(47, 194, 221);

$light-grey: #e1e1e1;
$dark-grey: #333;
$border-color: #bbb;
$link-color: #1eaedb;

$font-color: $primary-color;
$font-color-inverted: $primary-border-color;

$disabled-color: rgb(196, 198, 210);
$disabled-color-light: lighten($disabled-color, 18%);
$disabled-background-color: $primary-background-color;
$disabled-font-color: $disabled-color;
$disabled-border-color: $disabled-color;

$drawing-editor-highlight-color: rgb(255, 128, 66);

// Typography
$font-family: "FoundersGrotesk";
$font-family-condensed: "FoundersGroteskCond";

/* font metrics */
$fm-capitalHeight: 0.63;
$fm-ascender: 0.63;
$fm-descender: 0.37;
$fm-lineGap: 0.2;

/* compute font-size to get capital height equal desired font-size */

@function computeFontSize($desiredFontSize) {
  @return ($desiredFontSize / $fm-capitalHeight);
}

@function calcFontSize($desiredFontSize) {
  @return calc(#{computeFontSize($desiredFontSize)} * 0.1rem);
}

@function computeVerticalAlign($desiredFontSize) {
  $distanceBottom: $fm-descender;
  $distanceTop: ($fm-ascender - $fm-capitalHeight);
  $computedFontSize: computeFontSize($desiredFontSize);
  $result: (($distanceBottom - $distanceTop) * $computedFontSize);
  @return $result;
}

@function calcVerticalAlign($desiredFontSize) {
  @return calc(#{computeVerticalAlign($desiredFontSize)} * -0.1rem);
}

@function computeLineHeightWithVerticalAlign($desiredFontSize, $desiredLineHeight) {
  $valign: computeVerticalAlign($desiredFontSize);
  @return (($desiredLineHeight * $desiredFontSize) - $valign);
}

@function calcLineHeight($desiredFontSize, $desiredLineHeight) {
  @return calc((#{computeLineHeightWithVerticalAlign($desiredFontSize, $desiredLineHeight)}) * 0.1rem);
}

@function computeLineHeight($desiredFontSize, $desiredLineHeight) {
  @return ($desiredLineHeight * $desiredFontSize);
}

@function calcSingleLineHeight($desiredFontSize, $desiredLineHeight) {
  @return calc((#{computeSingleLineHeightWithVerticalAlign($desiredFontSize, $desiredLineHeight)}) * 0.3rem);
}

@function computeSingleLineHeightWithVerticalAlign($desiredFontSize, $desiredLineHeight) {
  $valign: computeVerticalAlign($desiredFontSize);
  @return (($desiredLineHeight * $desiredFontSize) - $valign);
}

@function calcLineHeightWithoutVerticalAlign($desiredFontSize, $desiredLineHeight) {
  @return calc((#{computeLineHeight($desiredFontSize, $desiredLineHeight)}) * 0.1rem);
}

$base-font-size: 10;
$base-font-size-h3: 3.1746rem;
$base-line-height: 1;
$base-heading-1-font-size: 2 * $base-font-size;
$base-heading-1-line-height: 3;
$base-heading-3-font-size: 1.25 * $base-font-size;
$base-heading-3-line-height: 2;
$base-heading-4-font-size: $base-font-size;
$base-heading-4-line-height: $base-line-height;

$base-label-font-size: $base-font-size;
$base-label-line-height: $base-line-height;

$font-weight-light: 300;
$font-weight-normal: 400; // Equivalent of normal.
$font-weight-medium: 500;
$font-weight-bold: 700; // Equivalent of bold

$font-size: calcFontSize($base-font-size);
$line-height: calcLineHeight($base-font-size, $base-line-height);

$navigation-bar-letter-spacing: 0.06rem;

// Dimensions
$button-height: 4rem;
$icon-button-dimensions: 2.4rem;

$suite-toolbar-height: 4rem;
$title-bar-height: 6rem;
$navigation-height: 4rem;

$sidebar-width: 24rem;
$onboarding-sidebar-width: 41rem;
$dashboard-width: 18.1rem;
$tool-sidebar-width: 4.4rem;

$header-height: $suite-toolbar-height + $title-bar-height + $navigation-height + 0.1rem;
$content-height: calc(100vh - #{$header-height});

$list-items-height: 4.4rem;
$menu-content-padding: 1.8rem;
$section-content-padding: 1.8rem;
$dialog-window-padding: 1.8rem;
$department-list-item-colored-border-size: 0.4rem;

// push down text in inputs to center text vertically
$input-padding-top: 1.1rem;

$builder-nav-height: 5.5rem;
$builder-icon-menu-width: 5.5rem;
$fly-out-width: 28rem;

$inspector-header-flyout-button-dimensions: $icon-button-dimensions;
$inspector-header-padding: 2rem;
$inspector-header-padding-bottom: 1rem;
$inspector-header-content-height: 6.6rem;
$inspector-header-height: $inspector-header-content-height + $inspector-header-padding + $inspector-header-padding-bottom;

$drawing-navigation-height: 3.6rem;
$drawing-navigation-header-height: 6rem;

$onboarding-padding: 2.4rem;

$section-header-height: $inspector-header-height;
$ccd-table-header-height: 6rem;

$table-row-height: 4.5rem;
$table-export-button-height: $button-height;
$table-export-buttons-margin-top: 2.4rem;
$table-export-buttons-margin-bottom: 2.8rem;
$table-button-group-height: $table-export-button-height + $table-export-buttons-margin-top + $table-export-buttons-margin-bottom;

$icon-menu-button-size: 5.5rem;
$icon-menu-button-gap: 0.5rem;
$icon-menu-button-indicator-size: 2rem;
$icon-menu-button-indicator-border-radius: 0.4rem;

// Misc
$global-radius: 0.1rem;
$button-radius: 0.1rem;
$button-radius-greater: 0.4rem;

// Range input overrides
//––––––––––––––––––––––––––––––––––––––––––––––––––

$track-color: $primary-border-color;
$thumb-color: $primary-background-color;

$thumb-height: 1.6rem;
$thumb-width: $thumb-height;
$thumb-radius: 50%;
$thumb-shadow-size: 0;
$thumb-shadow-blur: 0;
$thumb-shadow-color: transparent;
$thumb-border-width: 0.2rem;
$thumb-border-color: $lens-tool-color;

$track-width: 100%;
$track-height: 0.3rem;
$track-radius: $track-height / 2;
$track-shadow-size: 0;
$track-shadow-blur: 0;
$track-shadow-color: transparent;
$track-border-width: 0;
$track-border-color: transparent;

$contrast: 5%;

//------------------------------

$grey: #e8e9ee;