@import "../../../variables";

.furniture-menu-item-description {

  $description-text-box-padding: .5rem;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  padding-top: 1rem;

  .furniture-menu-item-description-text-min,
  .furniture-menu-item-description-text-max {
    max-width: 14rem;
    min-height: 2.6rem;
    padding-top: $description-text-box-padding;
    padding-bottom: $description-text-box-padding;
  }

  .furniture-menu-item-description-text-min {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 2.6rem;
  }

  .furniture-menu-item-description-button {
    width: 2.4rem;
  }

  .open {
    transform: rotate(90deg);
  }

  .close {
    transform: rotate(270deg);
  }
}

