@import "../../variables";


.menu-section {
  box-sizing: border-box;
  padding: $menu-content-padding;
  border-bottom: solid 1px $primary-border-color;

  &:last-child {
    border-bottom: none;
  }

  &.menu-section-with-title {

    .menu-section-with-title {

      border-bottom: none;

      .menu-section-title-container {
        .menu-section-title-row {
          background-color: transparent;
        }
      }

      + .menu-section-with-title {
        padding-top: 0;
      }

    }

  }

  .menu-section-title-container {

    padding-right: $menu-content-padding;
    margin-right: -$menu-content-padding;
    margin-bottom: $menu-content-padding;

    border-bottom: solid 1px $primary-border-color;

    .menu-section-title-row {

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding-left: $menu-content-padding;
      padding-right: $menu-content-padding;

      margin-left: -$menu-content-padding;
      margin-top: -$menu-content-padding;

      height: 4.5rem;
      width: calc(100% + #{$menu-content-padding} * 2);
      background-color: $secondary-background-color;

      box-sizing: border-box;

      text-transform: capitalize;

      .menu-section-title-content {
        display: flex;
        align-items: center;
      }

      .menu-section-icon {
        height: 2.4rem;
        padding-right: .6rem;
      }
    }

    .menu-section-action-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    + .menu-section {
      margin-top: -$menu-content-padding;
    }
  }

  h3, h4 {
    margin: 0;
  }

  h3 + div:not(.menu-section),
  h4 + div:not(.menu-section) {
    margin-top: 1.5rem;
  }


  .menu-section:first-child {
    padding-top: 0;
  }

  .menu-section {
    padding-left: 0;
    padding-right: $menu-content-padding;
    margin-right: -$menu-content-padding;

    &:last-child {
      padding-bottom: 0;
    }
  }

  > div:not(.menu-section):not(.menu-section-title-container), > .removable-block > div:not(.menu-section):not(.menu-section-title-container) {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

}