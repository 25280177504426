@import "../../variables";

.navigation-drop-down-item {

  margin-left: 1.8rem;

  .navigation-drop-down-item-container {
    height: $list-items-height;

    border-bottom: solid 1px $menu-separator-color;
    box-sizing: border-box;

    display: flex;
    align-items: center;

    .navigation-drop-down-item-content {
      height: 100%;
      cursor: pointer;
      box-sizing: border-box;
      padding: -.9rem $menu-content-padding;

      flex-grow: 1;

      display: flex;
      align-items: center;

      .navigation-drop-down-item-icon {
        height: 2.4rem;
        width: 2.4rem;
        padding-right: 0.5rem;
      }
    }

    > * {
      -ms-user-select: None;
      -moz-user-select: None;
      -webkit-user-select: None;
      user-select: None;
    }

  }
}