@import "../../../variables";

.floor-plan-fly-out {
  left: $sidebar-width;
  top: $title-bar-height + $navigation-height + $suite-toolbar-height;

  .floor-plan-fly-out-header {
    box-sizing: border-box;
    height: $inspector-header-height;
    width: 100%;
    padding: 1.5rem;
    border-bottom: solid 1px $menu-separator-color;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    .fly-out-close-button {
      align-self: flex-start;
      flex-shrink: 0;
    }

    h3 {
      // align-self: flex-start;
      flex-grow: 1;
      margin-left: 1rem;
      font-weight: 500;
      font-size: 1.8rem;
      text-transform: none;
    }
  }
}

