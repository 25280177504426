@import "~normalize.css";
@import "variables";
@import "~input-range-scss";

// Fonts
//––––––––––––––––––––––––––––––––––––––––––––––––––

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('assets/fonts/FoundersGrotesk-Light.eot');
  src: url('assets/fonts/FoundersGrotesk-Light.woff2') format('woff2'),
  url('assets/fonts/FoundersGrotesk-Light.woff') format('woff'),
  url('assets/fonts/FoundersGrotesk-Light.ttf') format('truetype'),
  url('assets/fonts/FoundersGrotesk-Light.otf') format('opentype'),
  url('assets/fonts/FoundersGrotesk-Light.svg#FoundersGrotesk-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('assets/fonts/FoundersGrotesk-Regular.eot');
  src: url('assets/fonts/FoundersGrotesk-Regular.woff2') format('woff2'),
  url('assets/fonts/FoundersGrotesk-Regular.woff') format('woff'),
  url('assets/fonts/FoundersGrotesk-Regular.ttf') format('truetype'),
  url('assets/fonts/FoundersGrotesk-Regular.otf') format('opentype'),
  url('assets/fonts/FoundersGrotesk-Regular.svg#FoundersGrotesk-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('assets/fonts/FoundersGrotesk-Medium.eot');
  src: url('assets/fonts/FoundersGrotesk-Medium.woff2') format('woff2'),
  url('assets/fonts/FoundersGrotesk-Medium.woff') format('woff'),
  url('assets/fonts/FoundersGrotesk-Medium.ttf') format('truetype'),
  url('assets/fonts/FoundersGrotesk-Medium.otf') format('opentype'),
  url('assets/fonts/FoundersGrotesk-Medium.svg#FoundersGrotesk-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('assets/fonts/FoundersGrotesk-Semibold.eot');
  src: url('assets/fonts/FoundersGrotesk-Semibold.woff2') format('woff2'),
  url('assets/fonts/FoundersGrotesk-Semibold.woff') format('woff'),
  url('assets/fonts/FoundersGrotesk-Semibold.ttf') format('truetype'),
  url('assets/fonts/FoundersGrotesk-Semibold.otf') format('opentype'),
  url('assets/fonts/FoundersGrotesk-Semibold.svg#FoundersGrotesk-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGrotesk';
  src: url('assets/fonts/FoundersGrotesk-Bold.eot');
  src: url('assets/fonts/FoundersGrotesk-Bold.woff2') format('woff2'),
  url('assets/fonts/FoundersGrotesk-Bold.woff') format('woff'),
  url('assets/fonts/FoundersGrotesk-Bold.ttf') format('truetype'),
  url('assets/fonts/FoundersGrotesk-Bold.otf') format('opentype'),
  url('assets/fonts/FoundersGrotesk-Bold.svg#FoundersGrotesk-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskCond';
  src: url('assets/fonts/FoundersGroteskCond-Lt.eot');
  src: url('assets/fonts/FoundersGroteskCond-Lt.woff2') format('woff2'),
  url('assets/fonts/FoundersGroteskCond-Lt.woff') format('woff'),
  url('assets/fonts/FoundersGroteskCond-Lt.ttf') format('truetype'),
  url('assets/fonts/FoundersGroteskCond-Lt.otf') format('opentype'),
  url('assets/fonts/FoundersGroteskCond-Lt.svg#FoundersGrotesk-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskCond';
  src: url('assets/fonts/FoundersGroteskCond-Reg.eot');
  src: url('assets/fonts/FoundersGroteskCond-Reg.woff2') format('woff2'),
  url('assets/fonts/FoundersGroteskCond-Reg.woff') format('woff'),
  url('assets/fonts/FoundersGroteskCond-Reg.ttf') format('truetype'),
  url('assets/fonts/FoundersGroteskCond-Reg.otf') format('opentype'),
  url('assets/fonts/FoundersGroteskCond-Reg.svg#FoundersGrotesk-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskCond';
  src: url('assets/fonts/FoundersGroteskCond-Med.eot');
  src: url('assets/fonts/FoundersGroteskCond-Med.woff2') format('woff2'),
  url('assets/fonts/FoundersGroteskCond-Med.woff') format('woff'),
  url('assets/fonts/FoundersGroteskCond-Med.ttf') format('truetype'),
  url('assets/fonts/FoundersGroteskCond-Med.otf') format('opentype'),
  url('assets/fonts/FoundersGroteskCond-Med.svg#FoundersGrotesk-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskCond';
  src: url('assets/fonts/FoundersGroteskCond-SmBd.eot');
  src: url('assets/fonts/FoundersGroteskCond-SmBd.woff2') format('woff2'),
  url('assets/fonts/FoundersGroteskCond-SmBd.woff') format('woff'),
  url('assets/fonts/FoundersGroteskCond-SmBd.ttf') format('truetype'),
  url('assets/fonts/FoundersGroteskCond-SmBd.otf') format('opentype'),
  url('assets/fonts/FoundersGroteskCond-SmBd.svg#FoundersGrotesk-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FoundersGroteskCond';
  src: url('assets/fonts/FoundersGroteskCond-Bold.eot');
  src: url('assets/fonts/FoundersGroteskCond-Bold.woff2') format('woff2'),
  url('assets/fonts/FoundersGroteskCond-Bold.woff') format('woff'),
  url('assets/fonts/FoundersGroteskCond-Bold.ttf') format('truetype'),
  url('assets/fonts/FoundersGroteskCond-Bold.otf') format('opentype'),
  url('assets/fonts/FoundersGroteskCond-Bold.svg#FoundersGrotesk-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}


// Base Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––
// NOTE
// html is set to 62.5% so that all the REM measurements throughout Skeleton
// are based on 10px sizing. So basically 1.5rem = 15px :)

html {
  font-size: 62.5%;
}

body {
  overflow: hidden;
  font-weight: $font-weight-normal;
  font-family: $font-family;
  color: $font-color;

  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: 0.03rem;
}


// Links
//––––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $font-color;
  text-decoration: none;
}

// Misc
//––––––––––––––––––––––––––––––––––––––––––––––––––

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid $light-grey;
}

// Typography
//––––––––––––––––––––––––––––––––––––––––––––––––––

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: $font-weight-normal;
  letter-spacing: 0.03rem;
}

h3 {
  font-size: calcFontSize($base-heading-3-font-size);
  line-height: calcLineHeight($base-heading-3-font-size, $base-heading-3-line-height);
  vertical-align: calcVerticalAlign($base-heading-3-font-size);
}

h4 {
  font-size: calcFontSize($base-heading-4-font-size);
  line-height: calcLineHeight($base-heading-4-font-size, $base-heading-4-line-height);
  vertical-align: calcVerticalAlign($base-heading-4-font-size);
}


label {
  letter-spacing: 0.03rem;
  font-size: calcFontSize($base-label-font-size);
  line-height: calcLineHeight($base-label-font-size, $base-label-line-height);
  vertical-align: calcVerticalAlign($base-label-font-size);
  font-weight: $font-weight-normal;
}

small {

  font-size: $font-size;
  line-height: $line-height;
  vertical-align: calcVerticalAlign($base-line-height);
  font-weight: $font-weight-light;
  letter-spacing: 0.03rem;
}

p {
  margin: 0 0 0.5rem;
}

input {
  font-family: $font-family;
  font-size: $font-size;
  line-height: calcLineHeightWithoutVerticalAlign($base-font-size, $base-line-height);
  font-weight: $font-weight-normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.03rem;
  color: $font-color;
}

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  font-size: $font-size;
  line-height: normal;
  font-weight: $font-weight-normal;
}

select {
  font-size: $font-size;
  line-height: normal;
  font-weight: $font-weight-normal;
}


// Buttons
//––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  height: $button-height;
  padding: 0 3rem;
  color: $primary-color;
  text-align: center;
  letter-spacing: 0.03rem;
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: $button-radius;
  border: 1px solid $primary-color;
  cursor: pointer;
  box-sizing: border-box;
}

.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover {
  color: $dark-grey;
  border-color: lighten($dark-grey, 33.3%);
  outline: 0;
}

.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  color: $dark-grey;
  border-color: lighten($dark-grey, 33.3%);
  outline: 0;
}


.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}

.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover {
  color: #fff;
  background-color: $link-color;
  border-color: $link-color;
}

.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
  color: #fff;
  background-color: $link-color;
  border-color: $link-color;
}

input {

  &[type="file"] {
    font-size: calcFontSize($base-font-size);
    line-height: calcLineHeight($base-font-size, $base-line-height + 1.2);
  }

  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    height: 3.8rem;
    padding: 0.6rem 1rem; // The 6px vertically centers text on FF, ignored by Webkit
    background-color: #fff;
    border: solid 1px $input-border-color;
    border-radius: $global-radius;
    box-shadow: none;
    box-sizing: border-box;
  }
}

// Forms
//––––––––––––––––––––––––––––––––––––––––––––––––––

textarea,
select {
  height: 3.8rem;
  padding: 0.6rem 1rem; // The 6px vertically centers text on FF, ignored by Webkit
  background-color: transparent;
  border: solid 0.1rem $primary-border-color;
  border-radius: 0.2rem;
  box-shadow: none;
  box-sizing: border-box;
}

// Removes default styles on inputs for iOS
input {
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    appearance: none;

    // push down text in inputs to center it vertically
    padding-top: $input-padding-top;

    // fix padding hiding text in firefox
    @include firefox-only {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

/* Removes default style on numerical input element */
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  appearance: none;
  min-height: 6.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

select {
  appearance: none;
  color: $primary-color;
  letter-spacing: 0.03rem;
  text-decoration: none;
  text-transform: capitalize;
  text-align: left;
}

input {
  &[type="email"]:focus,
  &[type="number"]:focus,
  &[type="search"]:focus,
  &[type="text"]:focus,
  &[type="tel"]:focus,
  &[type="url"]:focus,
  &[type="password"]:focus {
    border: 1px solid $primary-color;
    outline: 0;
  }
}

textarea:focus,
select:focus {
  border: 1px solid $primary-color;
  outline: 0;
}

label,
legend {
  display: block;
  margin-bottom: .5rem;
}

fieldset {
  padding: 0;
  border-width: 0;
}

input {
  &[type="checkbox"],
  &[type="radio"] {
    display: inline;
  }
}

label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: $font-weight-normal;
}

// Disabled elements
//––––––––––––––––––––––––––––––––––––––––––––––––––

input,
textarea,
select {
  &:disabled, &.disabled {
    border-color: $disabled-color !important;
    color: $disabled-font-color !important;
    background-color: $disabled-background-color !important;
    cursor: default;
  }
}

a {
  &:disabled, &.disabled {
    color: $disabled-font-color !important;
    cursor: default;
  }
}

// Lists
//––––––––––––––––––––––––––––––––––––––––––––––––––

ul {
  list-style: none;
  margin: 0;
}

ol {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

ul {
  padding-left: 0;
  margin-top: 0;
}


// Custom Global Styles
//––––––––––––––––––––––––––––––––––––––––––––––––––

.invisibleElement {
  display: none;
}

.spacer {
  width: 100%;
  float: none;
  clear: both;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.servicebar--button.sc-service-bar:not(:last-child) {
  display: none;
}