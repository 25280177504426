@import '../../variables';

.projectname-input-content {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;


  .projectname-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: calc(100vh - #{$suite-toolbar-height} - #{$title-bar-height});

    .projectname-form {
      display: flex;
      flex-direction: column;

      .title {
        margin-bottom: .8rem;
      }

      .projectname-input {
        height: 6rem;
        width: calc(100vw - #{$onboarding-sidebar-width} - 36rem);
        padding-top: .8rem;
        border: solid .1rem $input-border-color;
        border-radius: .2rem;
        outline: solid .1rem $input-border-color;
        padding-left: 2rem;
        font-weight: 500;
        letter-spacing: 0.05rem;
        font-size: 3rem;
      }

      .project-data-selection-area {
        display: flex;

        margin-top: 3rem;

        width: 100%;

        justify-content: space-between;

        .project-data-selection {
          width: 49%;
        }
      }

      .project-further-information {
        margin-top: 3.6rem;

        cursor: pointer;

        .text-wrapper {
          width: 15.6rem;
          border-bottom: .1rem solid $primary-color;
        }
      }

      .projectname-submit-button {

        margin-top: 4.4rem;
        width: 16rem;
      }
    }


    &.advanced-settings {
      height: calc(100vh - #{$suite-toolbar-height} - #{$title-bar-height} - 7.6rem);
    }



  }

  .skip-area {
    height: 7.6rem;
    width: calc(100vw - #{$onboarding-sidebar-width});
    background-color: #6a7080;
  }
}