@import '../variables';

.people-header {
  height: $inspector-header-height !important;
  padding: $inspector-header-padding $inspector-header-padding $inspector-header-padding-bottom;
  border-bottom: solid 1px $inspector-list-divider-color;
  overflow: hidden;
  box-sizing: border-box;

  display: flex;

  h3 {
    text-transform: capitalize;
    display: inline-block;
    max-width: calc(#{$sidebar-width} - (#{$inspector-header-padding} * 2) - 1px);
    margin-bottom: 0;

    font-weight: 600;
    font-family: $font-family-condensed;
    font-size: calcFontSize(20);
    letter-spacing: 0.06rem;
  }
}
