@import '../../../variables';

.step-sidebar-description-text {
  margin-bottom: 1.6rem;
}

.step-sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100vh - #{$suite-toolbar-height} - #{$title-bar-height} - 25.5rem - 20rem);

  .step-sidebar-description-separator {
    margin-bottom: 1.8rem;
    border-top: .1rem solid $inspector-border-color;
  }

  .step-sidebar-shortcuts {
    display: flex;
    flex-direction: column;
    margin-bottom: .4rem;

    .step-sidebar-shortcuts-title {
      margin-bottom: .6rem;
    }

    .step-sidebar-shortcut {
      padding: 0.65rem .6rem 0 0.6rem;
      height: 1.8rem;
      margin-top: .6rem;
      background-color: #e8e9ee;
      border-radius: .36rem;
      width: fit-content;
    }

  }
}