@import "src/variables";

.blocking-header {
  height: $inspector-header-height !important;
  background-color: $third-background-color;
  padding: $inspector-header-padding $inspector-header-padding $inspector-header-padding-bottom;
  border-bottom: solid .1rem $inspector-border-color;
  overflow: hidden;
  box-sizing: border-box;

  display: flex;

  h3 {
    text-transform: capitalize;
    display: inline-block;
    max-width: calc(#{$sidebar-width} - (#{$inspector-header-padding} * 2) - 1px);
    margin-bottom: 0;

    font-weight: 600;
    font-family: $font-family-condensed;
    font-size: calcFontSize(20);
    letter-spacing: 0.06rem;
  }
}

.blocking-editor {
  display: inline-block;
  height: 100%;
  width: 100%;
  background-color: $third-background-color;

  position: relative;

  .floor-list-keeper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -20rem;
    text-align: center;
  }
  .floors-list {
    margin-top: 19.9rem;
    padding-right: 10rem;
    margin-left: 10rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 29.9rem);
    overflow-x: hidden;
    padding-bottom: 5rem;
    text-align: left;
    box-sizing: border-box;

    > div {
      /*margin-bottom: 5rem;*/
    }
  }

}