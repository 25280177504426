@import "../../variables";

.dropdown-menu {

  display: flex;
  flex-direction: column;

  width: 24rem;

  box-sizing: border-box;
  border-radius: 0.2rem;
  box-shadow: 0 4px 13px 0 rgba(55, 59, 81, 0.25);
  background-color: $primary-background-color;

  .dropdown-menu-header {
    padding: 1.8rem 1.8rem 1.5rem;

    border-bottom: 0.1rem solid $primary-border-color;

    h4 {
      margin-bottom: 1rem;
      font-weight: $font-weight-medium;
    }

    span {
      margin-bottom: 1rem;
    }
  }

  &.dark-mode {
    background-color: $secondary-background-color-dark-mode;
    color: $primary-color-dark-mode;
  }

}