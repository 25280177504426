@import '../../variables';

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: $icon-button-dimensions;
  width: $icon-button-dimensions;
}

