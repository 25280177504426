@import '../../variables';

.table-column-info-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .header-title {
    width: auto;
    height: 2rem;

    .text-inner {
      padding-top: .83rem;
    }
  }

  .info-button {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.info-body {
  margin-top: .1rem;
  padding: 1.2rem;
  box-shadow: 0 0 6px 0 rgba(208, 209, 212, 0.49);

  background-color: white;
  width: 24rem;

  .info-body-header {
    margin-bottom: .9rem;
  }
}