@import "../../../../variables";

.culture-lens-controller {

  .label-bottom, .label-top {
  display: flex;
    justify-content: space-between;

  .label-left {
    float: left;
    display: inline-block;
  }

  .label-right {
    float: right;
    display: inline-block;
  }

  }

  .label-bottom {
    margin-top: 0.9rem;
  }

  .label-top {
    margin-bottom: 0.9rem;
  }

  .dot {
    position: absolute;
    background-color: $primary-background-color;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border: solid 2px $lens-tool-color;
    box-sizing: border-box;
  }

  #culture-lens-controller-main {
    position: relative;
    width: calc(#{$fly-out-width} - #{$menu-content-padding} * 2 - 4px - 0.1rem);
    height: calc(#{$fly-out-width} - #{$menu-content-padding} * 2 - 4px - 0.1rem);
    border-radius: 4px;
    border: solid 0.1rem #d0d1d4;
    background-color: #ffffff;
    box-sizing: border-box;
  }

  .dot-area {
    width: 50%;
    height: 50%;
    position: absolute;
  }

  .dot-touch {
    position: absolute;

    width: 40px;
    height: 40px;
    padding: 12px 0 0 12px;
    box-sizing: border-box;
    margin: -12px 0 0 -12px;
  }

  #area-collaborate {
    left: 0;
    top: 0;
  }

  #area-create {
    left: 50%;
    top: 0;
  }

  #area-control {
    left: 0;
    top: 50%;
  }

  #area-compete {
    left: 50%;
    top: 50%;
  }

  #reddotted {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke: $lens-tool-color;
    stroke-dasharray: 1 4;
  }

  #greyback {
    fill: #f5f5f5;
  }

  svg {
    height: 100%;
    width: 100%;
  }

  .dgrey {
    fill: none;
    stroke-width: 1px;
    stroke: #d0d1d4;
  }

  .lgrey {
    fill: none;
    stroke-width: 1px;
    stroke: #eee;
  }

  line {
    shape-rendering: crispEdges;
  }
}