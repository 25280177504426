@import "../../../../variables";

.work-style-lens-controller {

  > label {
    padding-bottom: 2rem;
    border-bottom: solid 0.1rem $primary-border-color;
  }

  .work-style-lens-slider {
    background-color: $primary-background-color;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border: solid 2px $lens-tool-color;
    box-sizing: border-box;
  }

}