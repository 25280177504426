@import "../../variables";

.list-menu-item {
  .open-submenu {
    margin-right: 1rem;
  }

  &.dark-mode {
    * > path {
      stroke: white;
    }
  }
}

