@import "../../../variables";

.step-content-footer {
  left: 41rem;
  bottom: 0;
  position: absolute;
  height: 7.6rem;
  width: calc(100vw - 41rem);

  background-color: #f6f7f9;

  .step-content-footer-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    .text-wrapper {
      width: fit-content;
    }

    .step-content-footer-link {
      display: flex;
      align-items: center;
      margin-left: 1rem;

      cursor: pointer;

      .text-wrapper {
        border-bottom: .1rem solid $primary-color;
      }
    }
  }
}