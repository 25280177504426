@import "../../variables";

.divider-button {
  height:14px;
  width:11px;
  padding: 0;
  border-radius: 0px;
  background-color: transparent;

  &.no-border {
    border: none;
    border-radius: 0;
  }

  &.invisible {
    opacity: 0;
    cursor: initial;
  }

  &.disabled {
    cursor: default;
  }
}