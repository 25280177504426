@import "../../variables";

.more-vertical-container {
  display: flex;
  align-items: center;

  height: 100%;

  border-left: solid .1rem $inspector-list-divider-color;
}

.vertical-menu-popper {
  z-index: 3;

  left: -.4rem !important;
  top: .2rem !important;
  width: 22rem;

  .dropdown-menu {
    width: auto;
  }
}