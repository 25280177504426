@import "../../../../variables";

.icon-field {

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: $menu-content-padding;
  margin-bottom: $menu-content-padding;

  width: 100%;
  height: 1.6rem;

  .label {
    margin-bottom: .1rem;
  }

  .icon {
    width: $icon-button-dimensions;
    margin-right: -1rem;
  }
}
