.block {
  height: 100%;

  .block-content {
    height: 100%;
  }

  .dptname {
    position: absolute;
    left: 1rem;
    bottom: .4rem;
    color: rgba(255, 255, 255, 1);
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.028rem;
    text-transform: uppercase;

  }
}