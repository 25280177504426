@import "../../variables";


.list-menu-nav-list-item {

  display: flex;
  align-items: center;

  height: $list-items-height;
  border-bottom: solid 1px $menu-separator-color;
  box-sizing: border-box;

  .list-menu-nav-item {

    box-sizing: border-box;
    cursor: pointer;
    padding-right: $menu-content-padding;
    padding-left: $menu-content-padding;

    display: flex;
    align-items: center;

    .list-menu-nav-text-disabled {
      color: $disabled-color;
    }

    .list-menu-nav-item-icon {
      height: 3.2rem;
      width: 3.2rem;
      margin-right: 1.9rem;
    }


    .list-menu-nav-item-arrow {
      height: 2.4rem;
      width: 2.4rem;
      float: right;
    }

    &.disabled {
      cursor: default;
    }

  }

}
