@import "../../variables";

.inspector {
  position: relative;
  z-index: 2;
  background-color: $primary-background-color;
  border-right: solid 1px $primary-border-color;
  width: $sidebar-width;
  height: calc(100vh - #{$header-height});
  box-sizing: border-box;
  flex-shrink: 0;
}