@import '../variables';

.floorplan-content {
  height: calc(100vh - #{$header-height});
  width: calc(100vw - (#{$sidebar-width} + #{$dashboard-width})) !important;
  overflow: hidden;

  &.onboarding {
    height: calc(100vh - #{$suite-toolbar-height + $title-bar-height});
    width: calc(100vw - #{$onboarding-sidebar-width});
  }
}