
.tags-menu {

  .tag-form-container {

    display: flex;
    flex-wrap: wrap;

    .tag {

      margin-right: 0.9rem;
      margin-bottom: 0.9rem;

    }

  }
}
