@import '../../variables';

.drop-down-item {

  //border-bottom: solid .2rem $primary-border-color;
  border-right: solid .2rem $primary-border-color;

  .drop-down-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $list-items-height;

    .drop-down-content {
      display: flex;
      align-items: center;
      text-transform: capitalize;

      .drop-down-icon {
        padding-left: .8rem;
        padding-right: 1rem;
        margin-bottom: .2rem;
      }

    }

    .drop-down-arrow {
      padding-right: 1rem;
      padding-left: .8rem;
    }

  }


}