@import "../../variables";


.file-input {

  box-sizing: border-box;
  border: dashed 0.1rem $primary-border-color;
  height: 13rem;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  input[type='file'] {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    &::-webkit-file-upload-button {
      -webkit-appearance: push-button;
      font: inherit;
    }

  }

  &.over {
    background-color: $secondary-background-color;
  }

}