@import "../../variables";


.ccd-table {

  * > span, div {
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;
  }

  table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: $table-row-height;
  }

  td {
    vertical-align: middle;
    border-bottom: 0.1rem solid $light-grey;
  }

  th {
    font-weight: $font-weight-light;

    font-size: 1.3rem;
    line-height: calcLineHeight(9, 1.5);

    span {
      vertical-align: calcVerticalAlign($base-font-size);
    }
  }

  th,
  td {
    text-align: left;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  .ccd-table-header {
    border-bottom: 0.1rem solid $light-grey;
    overflow: hidden;
    height: 2rem;
  }

  .ccd-table-body {
    height: calc(100vh - #{$header-height} - #{$section-header-height} - #{$ccd-table-header-height} - #{$table-button-group-height});
    overflow-x: hidden;

    tr {
      height: $table-row-height;
    }

  }

}