.application-profiles-flyout-menu {

  .select-button-label {
    margin-top: 1.5rem;
  }

  .application-profiles-flyout-menu-header {
    display: block;
    font-weight: 500;
    font-size: 1.5rem;
  }

  .application-profiles-flyout-menu-form-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: 0.2rem;
  }

  .application-style-form-buttons {
    display: flex;
    margin-top: 2rem;
    margin-left: 0.2rem;
    flex-direction: row;

    .application-style-customized-button {
      width: 100%;
      margin-right: 0;
    }
  }
}