@import "../../variables";

$normal-height: 3rem;
$vertical-height: 3.6rem;

.number-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .number-input-label {
    padding-right: 0.5rem;
  }

  .input-container {
    position: relative;

    width: 7.2rem;
    height: $normal-height;

    input {

      width: 100%;
      height: 100% !important;

      font-size: calcFontSize(9);
      line-height: normal;
      text-align: left;

      padding: $input-padding-top 2.8rem 0.7rem 0.8rem;
      border-radius: 0.2rem;
      border: solid 0.1rem $primary-border-color;
      box-sizing: border-box;

      &.vertical-style {
        font-size: 2.8rem;
        padding-top: 1.8rem;
        font-weight: 600;
        text-align: right;
        background-color: #f6f7f9;
        font-family: $font-family-condensed;
      }
    }

    .number-input-button-group {

      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
      bottom: 0.1rem;

      height: $normal-height - 0.1rem * 2;
      width: 2rem;

      border-left: solid 0.1rem $primary-border-color;
      background-color: white;

      &.disabled {
        border-color: $disabled-border-color;
      }

      button {
        position: relative;
        height: 50%;
        width: 100%;

        padding: 0;
        margin: 0;

        border: none;
        border-radius: 0;

        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
          background-color: transparent !important;
          cursor: auto;
        }

        &:last-child {
          border-top: solid 0.1rem $primary-border-color;
        }

        svg {
          height: 2rem;
        }

      }

      &.vertical-style {
        height: $vertical-height - 0.1rem * 2;
      }
    }

    &.vertical-style {
      width: auto;
      height: $vertical-height;
    }

  }


  &.vertical-style {
    flex-direction: column;
    align-items: start;
    height: 5.8rem;
  }


}

