@import "src/variables";

.buildings-drop-down {
  display: flex;
  flex-direction: row;

  align-items: center;

  border-bottom: solid .1rem $inspector-list-divider-color;

  .drop-down {
    width: 100%;

    .drop-down-item {
      border-right: 0;
    }
  }

  .buildings-drop-down-menu {
    z-index: 1;
    top: .2rem !important;
    width: 23rem;

    .dropdown-menu {
      width: auto;
    }
  }

  .more-vertical-container {
    height: $list-items-height;
  }

}