.application-profiles-units-table {

  .application-profiles-expander-column {
    width: 4rem;
  }

  .application-style-name-column {
    width: 20rem;

    input {
      width: 20rem;
    }
  }

  .application-type-name-column {
    width: 20rem;
  }

  td.application-type-name-column {
    padding-top: .8rem;
  }

  .application-profiles-selection-column {
    width: 10em;
  }

  .application-profiles-number-column {
    width: 15rem;
    padding-left: 1rem;
  }

  .application-profiles-toggle-column {
    width: 40rem;
  }


}