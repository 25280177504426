@import "../../../../variables";

.select-field {
  display: flex;
  flex-direction: column;

  .label {
    height: 1.6rem;
    margin-top: -.8rem;
    margin-bottom: 1.65rem;
  }

  .data {
    margin-bottom: 1.2rem;

    .data-inner {
      text-transform: capitalize;
    }
  }

}
