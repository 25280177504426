@import "../../../variables";

.builder-overview-element-icon-button {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .builder-overview-element-icon {
    height: 30%;
    width: 30%;

    padding-bottom: 1rem;

    display: flex;
    align-items: center;
  }
}