@import "../../variables";

.button-component {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.2rem;
  outline: none !important;

  .button-inner {
    display: inline-block;
    line-height: calcLineHeight($base-font-size, $base-line-height);
    text-align: center;

    span {
      vertical-align: calcVerticalAlign($base-font-size);
    }

    &.right-icon {
      padding-right: .4rem;
    }

  }

  &:hover {
    background-color: $secondary-background-color;
  }

  &:focus {
    outline: 0.2rem solid $outline-color;
    outline-offset: 0.1rem;
  }

  &:disabled, &.disabled {
    border-color: $disabled-color !important;
    color: $disabled-font-color !important;
    background-color: $disabled-background-color !important;
    cursor: default;
  }

  &.with-icon {

    padding-left: 1.2rem;
    padding-right: 1.8rem;
    justify-content: space-between;

    svg {
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      padding-right: 0.6rem;
    }
  }

  // variants

  &.outlined-button {
    border: 0.1rem solid $primary-color;
  }

  &.text-button {
    border: none;
    background-color: transparent;

    &:disabled {
      border: none !important;
      background-color: transparent !important;
    }

    &:hover {
      background-color: $secondary-background-color;
    }

  }

  &.contained-button {
    border-color: $primary-color;
    background-color: $primary-color;
    color: $primary-background-color;

    &:disabled {
      background-color: $secondary-background-color !important;
    }

    &:hover {
      background-color: $primary-color-light;
    }
  }

  &.filled-button {
    border-color: $primary-color;
    background-color: $primary-background-color;
    color: $primary-color;

    &:disabled {
      background-color: $disabled-color !important;
      color: black !important;
    }

    &:hover {
      background-color: $light-background-color;
    }
  }

  &.orange-button {
    border-color: $secondary-color;
    color: $secondary-color;

    &:disabled {
      background-color: $disabled-color !important;
    }

    &:hover {
      background-color: $light-background-color;
    }
  }

  &.green-filled-button {
    border: solid .1rem rgba(0, 107, 102, 0.29);
    background-color: #60bf82;
    color: white;
  }

  &.orange-filled-button,
  &.orange-filled-round-button {
    border-color: $orange-border-color;
    background-color: $secondary-color;
    color: white;

    &:disabled {
      background-color: #fbe7d5 !important;
      color: white !important;
      border-color: #faeeeb !important;
    }

    &:hover {
      background-color: $orange-border-color;
    }

    path > * {
      fill: white;
    }

    g > * {
      stroke: white !important;
    }
  }

  &.orange-filled-round-button {
    border-radius: 4rem;
  }

  &.inverted-button {
    color: $font-color-inverted !important;
    border-color: transparent;

    &:hover {
      background-color: rgb(55, 85, 100);
    }

    &:focus {
      border: none;
    }

  }

  &.round-button {
    border-radius: 2rem;

  }

  > * {
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;
  }

}