.button-toolbar-component {

  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .button-group:first-of-type:not(:last-of-type) {
    flex-grow: 1;
  }

  .button-component {
    display: flex;
  }

}