@import '../../variables';

.side-bar-icon-menu {
  width: calc(#{$icon-menu-button-size} - .1rem);
  height: 100%;

  border-right: solid .1rem $fly-out-border-color;
  background: #ffffff;
  z-index: 2;

  &.selected {
    box-shadow: inset -.5rem 0rem 1.1rem -.5rem rgba(27, 32, 49, 0.1);
  }

  .side-bar-icon-menu-list {

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .icon-menu-button-container {
      margin-top: $icon-menu-button-gap / 2;
      margin-bottom: $icon-menu-button-gap / 2;
      position: relative;
      height: $icon-menu-button-size;
      width: $icon-menu-button-size;
      overflow: hidden;

      .icon-menu-button-indicator {
        display: none;
      }

      &.selected {

        .icon-menu-button-indicator {
          display: block;
          box-sizing: border-box;
          background: #ffffff;

          position: absolute;
          top: 50%;
          margin-top: - $icon-menu-button-indicator-size /2;
          right: - $icon-menu-button-indicator-size /2 - $icon-menu-button-indicator-border-radius /2;

          width: $icon-menu-button-indicator-size;
          height: $icon-menu-button-indicator-size;

          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);

          border: solid 1px $fly-out-border-color;
          border-radius: $icon-menu-button-indicator-border-radius;

          box-shadow: 0 0 11px 0 rgba(27, 32, 49, 0.1);
        }

      }

      .icon-menu-button {
        height: $icon-menu-button-size;
        width: $icon-menu-button-size;
      }

    }
  }
}
