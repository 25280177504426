@import '../../../variables';

#pattern-builder {
  height: calc(100vh - #{$header-height});

  .main {
    height: calc(100vh - #{$header-height} - #{$builder-nav-height});
    display: flex;
  }

  .sidebar {
    padding: 0;

    &.left {
      width: $builder-icon-menu-width;
    }

    &.right {
      width: $sidebar-width;
    }
  }

  .content {
    width: calc(100vw - #{$sidebar-width} - #{$builder-icon-menu-width});
    overflow: hidden;
  }

  #x {
    position: absolute;
    left: 300px;
    top: 150px;
  }

  #y {
    position: absolute;
    left: 200px;
    top: 150px;
  }

  #bm {
    position: absolute;
    left: 100px;
    top: 200px;
  }

  .undo_container {
    display: flex;
    margin-right: 2rem;
  }

  .app_builder_undo, .app_builder_redo {
    height: 5.4rem;
    width: 3rem;
    padding-top: 0.5rem;
  }

}