
.project-information {

  .project-information-form {
    max-width: 50rem;

    display: flex;
    flex-direction: column;

    input,
    textarea,
    select,
    .project-information-form-buttons {
      flex: auto;
      margin-top: 1.5rem;
    }

    .project-information-form-buttons {
      display: flex;
      justify-content: flex-end;
    }

    .title {
      margin-top: 1.5rem;
      margin-bottom: -1rem;
    }

    .title:first-child {
      margin-top: 3rem;
    }
  }


}