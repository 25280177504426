@import '../../../variables';

$plan-context-menu-indicator-size: 1.5rem;
$plan-context-menu-item-size: 4rem;

$scale-input-width: 16rem;
$scale-input-height: 4.6rem;
$scale-input-padding: .5rem;

.scale-input-popup {


  width: $scale-input-width;
  height: $scale-input-height;
  z-index: 1;

  -webkit-box-shadow: 0 0 6px 0 rgba(208, 209, 212, 0.49);
  -moz-box-shadow: 0 0 6px 0 rgba(208, 209, 212, 0.49);
  box-shadow: 0 0 6px 0 rgba(208, 209, 212, 0.49);

  border: solid 1px rgba(208, 209, 212, 0.53);

  .scale-input-container {
    display: flex;
    padding: $scale-input-padding;
    align-content: space-between;
    height: $scale-input-height - 2 * $scale-input-padding;
    background-color: white;

    .scale-input {
      width: 6rem;
      height: $scale-input-height - 2 * $scale-input-padding;
      margin-right: $scale-input-padding;
    }

    .scale-text-wrapper {
      width: 2.2rem;
      padding-top: 1.9rem;
    }

    .scale-button {
      width: 6rem;
      height: $scale-input-height - 2 * $scale-input-padding;
    }

  }

  .scale-input-arrow {
    box-sizing: border-box;
    z-index: -1;

    position: absolute;
    bottom: 3.9rem;
    right: 7.5rem;
    background-color: white;

    width: $plan-context-menu-indicator-size;
    height: $plan-context-menu-indicator-size;

    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);

    border-bottom: solid 1px rgba(208, 209, 212, 0.53);
    border-left: solid 1px rgba(208, 209, 212, 0.53);

    -webkit-box-shadow: -2px 2px 2px 0px rgba(208, 209, 212, 0.49);
    -moz-box-shadow: -2px 2px 2px 0px rgba(208, 209, 212, 0.49);
    box-shadow: -2px 2px 2px 0px rgba(208, 209, 212, 0.49);
  }
}

