@import "../../variables";

$fly-out-header-color-height: .6rem;

.fly-out {
  box-sizing: border-box;
  border-left: solid 1px $fly-out-border-color;
  border-right: solid 1px $fly-out-border-color;
  background-color: $primary-background-color;

  overflow: hidden;

  position: fixed;
  /*top: $header-height;*/
  top: 0;

  height: $content-height;
  width: $fly-out-width;

  .fly-out-header-color {
    height: $fly-out-header-color-height;
  }

  .fly-out-header {
    box-sizing: border-box;
    height: $inspector-header-height - $fly-out-header-color-height !important;
    width: 100%;
    padding-right: 1.3rem !important;
    border-bottom: solid 1px $menu-separator-color;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 0;
      text-transform: capitalize;
    }

    .fly-out-buttons {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;

      .fly-out-close-button {
        margin-left: 1rem;
      }
    }

  }

  .fly-out-content {
    overflow-x: hidden;
    height: calc(100vh - #{$header-height} - #{$inspector-header-height});
  }
}
