@import "src/variables";

.marker-dialog {

  .marker-dialog-inner {

    .marker-name {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    .toggle-switch {
      margin-top: 2rem;
      padding: 1rem;
    }

  }

}