.pattern-styles-units-table {


  .pattern-styles-expander-column {
    width: 4rem;
  }

  .pattern-styles-name-column {
    width: 15rem;

    input {
      width: 10rem;
    }
  }

  .pattern-styles-selection-column {
    width: 20.6rem;
  }

  .pattern-styles-number-column {
    width: 20rem;
    padding-left: 1rem;
  }

  .pattern-styles-number-optional-column {
    width: 20rem;
    padding-left: 1rem;

    input {
      width: 6rem;
    }
  }

  .pattern-styles-toggle-column {
    //width: 30rem;
    width: auto;
  }

}