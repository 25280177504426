@import "../../../variables";

.builder-overview-element {

  cursor: pointer;
  box-shadow: 0 0 .2rem 0 $fly-out-border-color;

  height: 20rem;
  width: calc(33% - 6rem);
  min-width: 28rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1.5rem 2rem 2rem;

  .builder-overview-element-content {
    display: flex;
    flex-direction: column;

    .element-content-header {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .builder-overview-element-title {
        .title-edit {

          font-weight: $font-weight-medium;
          height: 2.5rem;

          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-left: 0;

          border-color: transparent;

          &:focus {
            border-color: $primary-color;
            text-overflow: unset;
            overflow: unset;
            padding-left: 1rem;
          }


        }

      }


    }

    .element-content-subheader {
      color: $dark-grey;
      font-weight: $font-weight-light;
    }

    .svgImage {
      align-self: flex-end;
      float: right;
      margin-top: 4rem;

      stroke: #000000;
      fill: #ffffff;
      stroke-width: 1;
      transform: scaleY(-1);
    }
  }

  > * {
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;
  }
}