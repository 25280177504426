@import "src/variables";

.corporate-units-table {
  margin-left: 2rem;
  margin-right: 2rem;

  th {
    letter-spacing: .1rem;
    font-size: 1.1rem;
  }

  .ccd-table-header {
    font-size: 3rem;
  }

  .name-header {
    margin-left: 5rem;
  }

  .row-selected {
    td {
      background-color: $secondary-background-color;
    }

    .corporate-expander-name-column {
      background-color: transparent;

      .expander-name-column-content {
        background-color: $secondary-background-color;
      }

    }
  }

  .corporate-expander-icon-column {
    width: 22rem;
    border-bottom: none;

    .expander-name-column-cell-container {

      .expander-name-column-content-container {
        margin-top: -0.1rem;
        border-top: 0.1rem solid $light-grey;
        border-bottom: 0.1rem solid $light-grey;

        .expander-name-column-content {
          display: flex;
          align-items: center;
          justify-content: space-between;

          height: 4.4rem;
          padding-right: 1rem;

          border-left: $department-list-item-colored-border-size solid transparent;

          .unit-icon {
            display: flex;
            align-items: center;
            padding-right: 0.5rem;
          }

          form {
            width: 100%;

            input {
              height: 3rem;
              border-color: $input-border-color;
              width: 100%;
            }
          }
        }
      }

      &:not(.expandable) {
        .expander-name-column-content {
          padding-left: 2.4rem;

        }
      }


    }
  }

  .corporate-number-text-column {

    color: $disabled-color;
    padding-left: .7rem;

  }

  .corporate-name-column {
    width: 20rem;

    input {
      width: 90%;
    }
  }

  .corporate-code-column {

    width: 8rem;

    input {
      width: 6.7rem;
      height: 3rem;
      border-color: $input-border-color;
    }
  }

  .corporate-number-column {
    width: 12rem;
  }

  .corporate-selection-column {
    width: 14rem;
  }

  .corporate-color-column {
    width: 6rem;

    .color-picker-input {
      width: min-content;
    }
  }

  .corporate-desk-sharing-ratio {
    display: flex;
    align-items: center;
    margin-left: .3rem;

    .relation-representation {
      padding-top: .4rem;
      padding-right: .5rem;
    }

  }

}