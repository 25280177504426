
.color-picker {
  &.leftSide {
    margin-left: -18rem;
  }

  .chrome-picker {
    border-radius: .3rem;

    :nth-child(2) {
      :nth-child(2) {
        > * input {
          font-size: 1.4rem !important;
        }

        > * span {
          display: none !important;
        }

        :nth-child(2) {
          display: none;
        }
      }
    }
  }

}