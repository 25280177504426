@import "../../variables";

.icon-button {
  height: $icon-button-dimensions;
  width: $icon-button-dimensions;
  padding: 0;
  border-radius: 0.4rem;
  background-color: transparent;

  &.no-border {
    border: none;
    border-radius: 0;
  }

  &.invisible {
    opacity: 0;
    cursor: initial;
  }

  &.disabled {
    cursor: default;
  }


}