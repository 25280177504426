@import "../../variables";

.toggle-switch {

  display: flex;
  align-items: center;
  padding-right: 0.2rem;

  .noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  .toggle-switch-label {
    flex-grow: 1;
    margin: 0;
  }

  .toggle-switch-button {

    display: flex;
    align-items: center;
    height: 1.6rem;

    .switch {
      margin: 0;
      display: inline-block;
      height: 0.4rem;
      width: 3rem;
      background: $active-background-color;
      border: solid 0.1rem rgba(112, 112, 112, 0.3);
      border-radius: 1rem;
      cursor: pointer;
      transition: all 300ms cubic-bezier(.4, 0, .2, 1);

      .slider {
        height: 1.4rem;
        width: 1.4rem;
        margin-top: -0.6rem;
        margin-left: -0.1rem;
        border-radius: 1.2rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 0.1rem rgba(112, 112, 112, 0.5);
        background-color: #ffffff;
        transition: all 300ms cubic-bezier(.4, 0, .2, 1);
      }

    }

    input {
      display: none;
    }

    input:checked + .switch .slider {
      transform: translate3d(100%, 0, 0);
    }

    input:checked + .switch {
      background: radial-gradient(circle at 50% 50%, rgb(54, 211, 153), rgb(54, 211, 153) 861%);;
    }

    &.disabled {

      .switch, .switch .slider {
        border-color: $disabled-border-color !important;
        background-color: $disabled-background-color !important;
        cursor: unset;
      }


    }
  }

}
