@import "../../../variables";

.builder-overview-group {

  width: calc(100% - 10rem);
  height: calc(100vh - #{$header-height} - #{$builder-nav-height} - 12rem);
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 5rem;
  padding-bottom: 20px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #999999;
  }


  .builder-overview-group-content {
    width: 100%;
    padding-top: 1px;

    display: flex;
    flex-wrap: wrap;

  }

  .icon-button {
    flex-shrink: 0;
    align-self: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: .6rem;
  }

  .overview-header {
    margin-bottom: 5rem;
    margin-left: 1rem;
  }

}