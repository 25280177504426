@import '../../variables';

.floors-container {
  display: flex;

  .floors-content {
    width: calc(100vw - #{$sidebar-width});

    &.onboarding {
      width: calc(100vw - #{$onboarding-sidebar-width + $sidebar-width});
    }
  }
}