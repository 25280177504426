@import '../../variables';

$plan-context-menu-indicator-size: 1.5rem;
$plan-context-menu-item-size: 4.4rem;

.plan-context-menu {

  width: $plan-context-menu-item-size;

  -webkit-box-shadow: 0 0 6px 0 rgba(208, 209, 212, 0.49);
  -moz-box-shadow: 0 0 6px 0 rgba(208, 209, 212, 0.49);
  box-shadow: 0 0 6px 0 rgba(208, 209, 212, 0.49);

  border: solid 1px rgba(208, 209, 212, 0.53);

  > .plan-context-menu-item {
    padding: 0.6rem;
    width: $plan-context-menu-item-size;
    height: $plan-context-menu-item-size;
    z-index: 1;
  }

  > .plan-context-menu-item:hover {
    svg {
      background-color: #00405c;
    }
  }

  &[data-placement="left"] {
    margin-right: calc(#{$plan-context-menu-indicator-size} / 2 + 5px);
  }

  &[data-placement="right"] {
    margin-left: calc(#{$plan-context-menu-indicator-size} / 2 + 5px);
  }

  .plan-context-menu-arrow {
    display: block;
    box-sizing: border-box;
    z-index: -1;

    position: absolute;

    width: $plan-context-menu-indicator-size;
    height: $plan-context-menu-indicator-size;

    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);

    &.plan-context-menu-left-arrow {

      margin-left: $plan-context-menu-item-size - $plan-context-menu-indicator-size /2;

      border-top: solid 1px rgba(208, 209, 212, 0.53);
      border-right: solid 1px rgba(208, 209, 212, 0.53);

      -webkit-box-shadow: 2px -2px 2px 0px rgba(208, 209, 212, 0.49);
      -moz-box-shadow: 2px -2px 2px 0px rgba(208, 209, 212, 0.49);
      box-shadow: 2px -2px 2px 0px rgba(208, 209, 212, 0.49);
    }

    &.plan-context-menu-right-arrow {

      margin-left: - $plan-context-menu-indicator-size /2;


      border-bottom: solid 1px rgba(208, 209, 212, 0.53);
      border-left: solid 1px rgba(208, 209, 212, 0.53);

      -webkit-box-shadow: -2px 2px 2px 0px rgba(208, 209, 212, 0.49);
      -moz-box-shadow: -2px 2px 2px 0px rgba(208, 209, 212, 0.49);
      box-shadow: -2px 2px 2px 0px rgba(208, 209, 212, 0.49);
    }


  }

}

.plan-context-menu, .plan-context-menu-arrow {
  background-color: $primary-color;
  -webkit-backdrop-filter: blur(44.7px);
  backdrop-filter: blur(44.7px);
}
