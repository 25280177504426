@import "../../variables";

.navigation-bar {

  width: 100%;
  height: $navigation-height;

  display: flex;
  justify-content: space-between;

  background-color: $primary-background-color;
  border-bottom: solid 0.1rem $secondary-border-color;

  &.onboarding {
    display: none;
  }
}