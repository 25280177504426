@import "../../variables";


.input-group {

  display: flex;

  button {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }

  input {
    width: 100%;
  }

  .button,
  .input,
  input,
  button {

    height: 3rem;
    border-radius: 0;
    border-right: none;

    &:last-child {
      border-top-right-radius: $button-radius-greater;
      border-bottom-right-radius: $button-radius-greater;
      border-right-style: solid;
      border-right-width: 0.1rem;
    }

    &:first-child {
      border-top-left-radius: $button-radius-greater;
      border-bottom-left-radius: $button-radius-greater;
    }

  }

}