@import "../../../../variables";

.settings-application {
  height: 100%;
  border-left: solid 0.1rem $fly-out-border-color;

  .settings-application-chip {
    margin-bottom: .7rem;
    margin-right: .7rem;
  }


}