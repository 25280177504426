@import "../../variables";

.scenario-navigation {

  display: flex;
  justify-content: flex-end;
  flex-grow: 1;

  height: $navigation-height;

  &.dark-mode {
    background-color: $third-background-color-dark-mode;
  }

  .variation-menu-container,
  .variation-scroll-menu-container {

    border-left: solid 0.2rem $primary-border-color;

    height: $navigation-height;

    .scenario-navigation-tab {

      align-items: center;
      box-sizing: border-box;
      border-left: solid 0.1rem $primary-border-color;

      &:first-child {
        border-left-color: transparent;
      }

      &.active, &:hover {
        &[role='button'] {
          font-weight: $font-weight-medium;
        }

        .scenario-navigation-tab-content {
          border-bottom: solid 0.2rem $primary-color;

          .scenario-navigation-tab-text {
            margin-bottom: -0.2rem;
          }

          &.dark-mode {
            border-bottom: solid 0.2rem $primary-color-dark-mode;
            color: $primary-color-dark-mode;
          }

        }

        &.dark-mode {
          color: $primary-color-dark-mode;
        }

      }

      &:focus {
        outline: none;
      }

      &[role='button'] {
        padding-left: 1.8rem;
        padding-right: 1.8rem;

        text-align: center;
        letter-spacing: $navigation-bar-letter-spacing;
        color: $primary-color;
        cursor: pointer;

        span::before {
          display: block;
          content: attr(title);
          font-weight: $font-weight-medium;
          height: 1px;
          color: transparent;
          overflow: hidden;
          visibility: hidden;
        }

      }

      .scenario-navigation-tab-content {
        height: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .scenario-navigation-tab-text {
          display: block;
          text-align: center;
          line-height: calcLineHeight($base-font-size, $base-line-height);

          span {
            vertical-align: calcVerticalAlign($base-font-size);
            margin-top: -0.1rem;
          }


        }

        &.dark-mode {
          color: $primary-color-dark-mode;
        }

      }

      &.dark-mode {
        border-left: solid 0.1rem $dark-mode-border-separator;
      }

    }

    &.dark-mode {
      border-color: $dark-mode-border-separator;
    }
  }

  .variation-menu-container {
    display: flex;
  }

  .variation-scroll-menu-container {

    width: calc(25vw);

    .scenario-navigation-tab {

      height: $navigation-height;
    }
  }

  button.scenario-navigation-plus-button,
  button.scenario-navigation-more-button {

    flex: 0 0 auto;
    border-left: solid 0.1rem $primary-border-color;
    box-sizing: border-box;

    height: $navigation-height;

    &.dark-mode {
      border-color: $dark-mode-border-separator;
    }
  }

  button.scenario-navigation-more-button {
    display: flex;
    align-items: center;

    &.active {
      background-color: $secondary-background-color;
    }

  }

  button.scenario-navigation-plus-button {
    padding: 1rem;
    width: $navigation-height;
  }

  .undoRedo {
    display: flex;
    align-items: center;
    border-left: solid .1rem $border-color;

    padding-left: 1rem;
    margin-left: .5rem;
    margin-right: auto;

    &.dark-mode {
      border-color: $dark-mode-border-separator;
    }
  }

  .project-name-container {

    display: flex;
    align-items: center;

    margin-right: 1.8rem;

    color: $primary-color;
    opacity: 0.5;

    &.dark-mode {
      color: $primary-color-dark-mode;
    }
  }

  > * {
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;
  }

}