@import '../../../variables';

.step-sidebar-assistant {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 7.55rem;
  border-top: solid .1rem $inspector-border-color;
  margin-left: 2.4rem;
  padding-right: 2.4rem;

  .step-sidebar-assistant-button {
    margin: 0;
    width: 12.1rem;
    padding: 1.2rem;

    color: $secondary-color;
    border-color: $secondary-color;

    .button-inner {
      margin-bottom: .1rem;
    }

    g * {
      stroke: $secondary-color;
    }

    &:hover {
      background-color: rgba(255, 128, 66, 0.1);
    }
  }

  .assistant-text-wrapper {
    width: auto;
  }
}

