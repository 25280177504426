@import "../../variables";

$min-max-label-font-size: 1.4rem;
$min-max-label-line-height: 1.4rem;

.range-input {

  width: 100%;
  display: flex;
  flex-direction: column;

  .range-input-label-title {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  .range-input-min-max-label {

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 1rem;
    margin-bottom: 0.8rem;

    > div {

      display: inline-block;

      span {
        font-size: calcFontSize(9);
        vertical-align: calcVerticalAlign(9);
        line-height: calcLineHeight(9, $base-line-height);
      }
    }

  }

}