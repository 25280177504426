@import "../../variables";
@import "../settings-mixins";

.application-profiles {

  .application-profiles-buttons {
    @include style-buttons;

    .application-profiles-buttons-send {
      button > * {
        width: 6rem;
      }
    }
  }
}