@import '../../variables.scss';

$image-height: 18rem;

.step-sidebar {
  flex-direction: column;
  width: 41rem;
  z-index: 3;
  border-right: solid .1rem $inspector-border-color;

  .step-sidebar-image {
    height: $image-height;
  }

  .step-sidebar-description {
    padding: $onboarding-padding 0 $onboarding-padding $onboarding-padding;
    height: calc(100vh - #{$suite-toolbar-height + $title-bar-height} - 20.1rem - #{$image-height});

    .step-sidebar-scrollbar {
      .step-sidebar-scrollbar-container {
        height: calc(100vh - #{$suite-toolbar-height + $title-bar-height} - 20.1rem - #{$image-height});
        padding-right: $onboarding-padding;
      }
    }


    .step-sidebar-title {
      margin-top: 1rem;
      margin-bottom: 3rem;
    }

    &.expand {
      height: calc(100vh - #{$suite-toolbar-height + $title-bar-height} - 20.1rem + 7.65rem - #{$image-height});
    }
  }

  .step-sidebar-process-bar {
    height: .6rem;
    background-color: $inspector-border-color;
  }

  .step-sidebar-next-step {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 2.3rem;
    height: 3rem !important;
    background-color: $primary-color;

    .step-sidebar-next-step-text {
      display: flex;
      flex-direction: column;
      color: white;

      .step-sidebar-next-step-link {
        display: flex;
        align-items: center;

        .step-sidebar-next-step-link-text {
          display: flex;

          padding-bottom: .2rem;
          border-bottom: solid .1rem white;
        }
      }
    }

    .step-sidebar-next-step-button {
      margin: 0;
      padding: 1.5rem;
      min-width: 12.1rem;
      width: fit-content;
      justify-content: space-between;

      &.finish {
        background-color: #60bf82 !important;
        color: white;
        fill: blue !important;

      }

      &.disabled {
        opacity: .5;
      }
    }
  }
}