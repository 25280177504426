@import "../../../../variables";

.adjacency-create-dialog {

  .adjacency-priority {
    display: flex;
    justify-content: space-between;

    .list-menu-icons-item-icon-button.selected {
      background-color: $secondary-background-color;
      border-radius: 50%;
    }

  }

  .adjacency-marker-selection,
  .adjacency-department-selection {
    margin-top: 2rem;
  }

  .adjacency-marker-list,
  .adjacency-department-list {
    margin-right: -$dialog-window-padding;
    margin-left: -$dialog-window-padding;

    .selected-connectable {
      background-color: $secondary-background-color;
    }

  }

  .adjacency-department-selection {
    margin-bottom: 2rem;
  }

}