@import "../../variables";

.notifications-container {

  position: absolute;
  top: $header-height;
  left: 50%;
  padding-top: 2rem;
  z-index: 10;

  z-index: 2;

  .notifications-item {
    transform: translateX(-50%);

    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity 500ms ease-in;
    }
  }

}