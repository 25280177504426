@import "../../../variables";


.section-header {

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  height: $section-header-height;

  box-sizing: border-box;

  border-bottom: solid 1px $menu-separator-color;

  margin-left: - $section-content-padding;
  margin-right: - $section-content-padding;
  margin-top: - $section-content-padding;

  padding-left: $section-content-padding;
  padding-right: $section-content-padding;
  padding-bottom: $section-content-padding;

  .section-header-inner {
    display: flex;
    align-items: flex-end;
  }

}