$small-width: 3.7rem;
$small-height: 26.7rem;

.interferer-container-small {
  position: absolute;
  right: 0;
  bottom: 16.7rem;

  .interferer-content-small {
    display: flex;
    align-items: center;

    width: $small-width;
    height: $small-height;
    background-color: #e7dfb7;

    .content-title {
      transform-origin: top left;
      transform: rotate(-90deg) translate(-100%);
      white-space: nowrap;
      width: auto;
      margin: -350% 25% 25% 25%;
    }
  }
}

.interferer-container-big {
  position: absolute;
  right: 0;
  bottom: 2.4rem;

  .interferer-content-big {
    width: 27.6rem;
    height: 41rem;

    border-radius: .2rem;
    box-shadow: 0 .4rem 13px 0 rgba(55, 59, 81, 0.25);
    background-color: white;

    .content-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 10.6rem;
      box-sizing: border-box;
      padding: .8rem .8rem 3rem 3rem;

      background-color: #e7dfb7;

      .button-section {
        display: flex;
        justify-content: flex-end;
      }

      .text-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 3.9rem;
      }
    }

    .content-body {
      display: flex;
      flex-direction: column;
      height: 30.4rem;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 2.4rem;

      .info-item-container {
        margin-top: -1rem;
        margin-bottom: -1rem;

        .info-item {
          display: flex;
          width: 100%;

          padding-bottom: .8rem;

          &:last-child {
            padding-bottom: 0;
          }

          .icon-wrapper {
            width: 1.2rem !important;
            height: 1.2rem !important;
            padding-right: .8rem;

            .svg {
              width: 1.2rem;
              height: 1.2rem !important;
            }
          }

          .text-wrapper {
            width: 80%;
          }
        }
      }

      .redirect-button {
        width: 100%;
      }

    }
  }


}