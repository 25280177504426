@import '../../../variables';

.test-fit-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .test-fit-styles {
    display: flex;
    flex-direction: column;

    margin-top: 1.2rem;

    .test-fit-style-row {
      display: flex;

      justify-content: space-between;
      margin-bottom: 1rem;

      .labeled-icon-button {
        height: 7.2rem;
        width: 8.5rem;

        .icon-wrapper {
          margin-top: -.4rem;
        }

        .text-wrapper {
          margin-top: .4rem;
        }
      }
    }
  }

  .test-fit-input {
    .test-fit-input-header {
      display: flex;
      align-items: center;
      flex-direction: row;
      color: #b1b3b8;

      margin-top: 1.6rem;
    }

    .test-fit-input-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 8rem;

      .number-input {
        width: 17rem;
      }

    }
  }

  .test-fit-separator {
    margin-top: 1rem;

    border-top: .1rem solid $inspector-border-color;

    margin-right: -$onboarding-padding;
  }

  .test-fit-dashboard-container {
    padding-top: 2rem;
    padding-bottom: 1.6rem;

    .test-fit-dashboard-info {
      color: #b1b3b8;
      padding-bottom: .4rem;
    }

    .test-fit-dashboard {
      display: flex;

      justify-content: space-between;

      margin-top: 1rem;

      .test-fit-dashboard-values {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 14rem;

        .test-fit-dashboard-value {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          height: 2.8rem;

          > :not(:last-child) {
            width: auto;
            padding-right: .4rem;
          }

          .text-wrapper {
            transition: background-color .1s linear;
            width: auto;
            padding: .5rem .5rem .35rem .5rem;
            margin-top: -.5rem;
            margin-left: -.5rem;
            border-radius: .6rem;

            &.highlight {
              background-color: rgba(245, 133, 78, .4);
              transition: background-color .4s linear;
            }
          }
        }

        .test-fit-dashboard-label-value {
          > :first-child {
            margin-bottom: .5rem;
          }
        }
      }

      .test-fit-dashboard-dots {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 9rem;

        .test-fit-dashboard-distribution {
          display: flex;
          height: 1.2rem;
          width: 16.9rem;

          :not(:last-child) {
            border-right: .1rem solid white;
          }
        }

        .test-fit-dashboard-distribution-value {
          display: flex;

          .distribution-color {
            height: 1rem;
            width: 1rem;
            border-radius: .5rem;

            margin-right: 1rem;
          }

          .text-wrapper {
            width: auto;
          }

        }
      }
    }
  }

  .test-fit-concat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 7rem;

    .text-wrapper {
      margin-top: 1rem;
    }
  }

}