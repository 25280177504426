@import "../../variables";

@mixin notificationColor($color) {
  border-color: $color;
  color: $color;

  .notification-action .notification-action-button {
    background-color: $color;
    border-color: $color;
  }
}

.notification {

  width: 32rem;

  border-top: solid 0.2rem;
  border-radius: 0.1rem;
  box-shadow: 0 0.4rem 1.3rem 0 rgba(55, 59, 81, 0.25);
  background-color: $primary-background-color;

  padding-top: 1.5rem;
  padding-left: 1.8rem;
  padding-bottom: 1.8rem;

  margin-bottom: 0.9rem;

  .notification-content {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .notification-text {

      flex-grow: 1;

      display: flex;
      flex-direction: column;

      padding-right: 1.4rem;

      .notification-title {

        height: 2.4rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        font-weight: 500;

        text-transform: capitalize;
        margin-bottom: 0.8rem;
      }

      .notification-message {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
      }

    }

    .notification-action {
      align-self: flex-start;

      .notification-action-button {
        margin-right: 1.4rem;
      }
    }
  }

  &.error {
    @include notificationColor($error-color);
  }

  &.warn {
    @include notificationColor($warn-color);
  }

  &.info {
    @include notificationColor($info-color);
  }

  &.success {
    @include notificationColor($success-color);
  }

}