@import '../../../variables';

.step-sidebar-description-text {
  margin-bottom: 1.6rem;

}

.step-sidebar-description-separator {
  margin-bottom: 1.8rem;
  border-top: .1rem solid $inspector-border-color;
}

.step-sidebar-info {
  .step-sidebar-info-item {
    display: flex;
    flex-direction: row;
    margin-top: .8rem;

    .step-sidebar-info-item-text {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;
      margin-top: .7rem;

      width: 90%;

      .step-sidebar-info-item-title {
        margin-bottom: .6rem;
      }
    }
  }
}