.layout-style {

  .layout-style-picker {
    margin-top: 0.8rem;
    margin-bottom: 2rem;
  }

  .layout-style-form-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: 0.2rem;

    .layout-style-customized-button {
      width: 100%;
      margin-right: 0;
    }
  }
}