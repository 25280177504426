@import "../../variables";

.general-settings {

  .general-settings-content {
    display: flex;
    flex-direction: column;

    max-width: 36rem;

    .use-toggle-components {
      padding-top: 2rem;

      display: flex;
      flex-direction: column;

      .toggle-switch {
        height: 3rem;
      }

      .calculation-text-outerClass {
        margin-bottom: 1rem;

        .calculation-text {
          font-weight: $font-weight-medium;
        }
      }
    }

    .general-settings-form-buttons {
      border-top: .1rem solid $border-color;
      justify-content: flex-end;
      margin-top: 2rem;
      padding-top: 2rem;
      display: flex;
      flex-direction: row;
      margin-right: -1rem;
    }
  }
}