@import "../../variables";

.step-container {
  display: flex;
  width: 100%;
  height: 100%;

  .step-content {
    width: calc(100vw - 41rem)
  }
}