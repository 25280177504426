@import "../../variables";

.select-button {

  width: 100%;
  padding-right: 3rem;
  padding-left: 1.8rem;
  // push down text in select box to center it vertically
  padding-top: $input-padding-top;

  /* Dropdown caret as SVG background image */
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path data-name='Pfad 874' d='M17 10.5l-5 4.95-5-4.95z' fill='%454b5a'/></svg>");
  background-size: 2rem;
  background-position: calc(100% - 1rem) center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-origin: padding-box;

  option {
    text-transform: capitalize;
  }

}

.select-button-label {
  margin-bottom: 0.9rem;
}
