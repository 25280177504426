@import "../../variables";

.spacing-information {


  .spacing-settings-form {

    display: flex;
    flex-direction: column;

    max-width: 36rem;

    .number-input {
      display: flex;
      align-items: center;
      height: $list-items-height;
    }

    .walls-slider-zone {
      border-top: .1rem solid $border-color;
      margin-top: 2rem;
      padding-top: 2rem;
    }

  }

  .info-image-popper-icon {

    .info-expandable {
      margin-left: 0.6rem;
      margin-bottom: -0.7rem;
    }
  }

  .spacing-form-buttons {
    align-self: flex-end;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    margin-right: -1rem;
  }


}