@import "../../../variables";

.furniture-menu {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  user-select: none;

  .fly-out-list-container {
    opacity: 1;
    padding-left: $menu-content-padding;
  }

  polygon, polyline {
    stroke: #000000;
    fill: #ffffff;
    stroke-width: 1;
  }


  .builder-fly-out {
    left: $builder-icon-menu-width;
    background-color: #ffffff;
    border-left: none;

    top: $header-height + 5.5rem;
    height: calc(100vh - #{$header-height} - 5.5rem);

    .fly-out-header-color {
      height: 0;
    }

    .fly-out-header {
      display: flex;
      align-items: center;
      height: 4.5rem !important;
      padding-left: 1.8rem;

      > h3 {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: -.6rem !important;
      }

      .fly-out-buttons {
        height: auto;
      }
    }


    .fly-out-content {

      overflow-x: hidden;
      height: calc(100vh - #{$header-height} - #{$inspector-header-height});
    }

    .fly-out-list-item {

      min-height: 10.8rem;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;

      border-bottom: solid 1px $menu-separator-color;

      .list-item-svg {
        display: flex;
        padding-top: $menu-content-padding;
      }

      .list-item-text-area {
        display: flex;
        flex-direction: column;
        width: 100%;

        padding-left: $menu-content-padding;
        padding-right: 1rem;
        padding-bottom: 3rem;

        .list-item-size-container {
          margin-top: 1rem;

          .list-item-size {
            color: $disabled-font-color;
          }
        }
      }
    }
  }

  .import-button-container {
    display: flex;
    height: 5.5rem;
    justify-content: center;
    align-items: center;
    z-index: 2;

    border-top: solid .1rem $fly-out-border-color;
    border-right: solid .1rem $fly-out-border-color;

    .import-button {
      margin-right: 0;
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}

