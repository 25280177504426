@import "src/variables";

.floor-container {
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.floor {
  /* flex: 1 1 auto;*/

  height: 50px;
  position: relative;

  .blocks-row {
    display: block;
    height: 100%;
    width: 100%;
    white-space: nowrap;

  }

  .block {
    //float:left;
    display: inline-block;
    position: relative;
    z-index: 2;
    margin-right: 2px;
  }

  .rowback {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 0;

  }

}

.floors-list {
  h3 {
    font-size: 1.5rem;
    color: #00293b;
    font-weight: 500;
    letter-spacing: 0.032rem;
    margin: 5rem 0 1rem 0;
    text-transform: capitalize;
  }

  .floor-usage {
    width: calc(100% + 10rem);
    font-size: 1.5rem;
    color: #00293b;
    letter-spacing: 0.032rem;

    box-sizing: border-box;
    padding-right: 5rem;
    padding-bottom: 1rem;

    border-bottom: 0.2rem solid #f4f4f4;
    margin-top: -1rem;
    margin-bottom: 2rem;

    .planned-cleaning {
      display: flex;
      align-items: flex-end;
      width: 15rem;

      .planned {
        margin-top: 1rem;
      }
    }


    .remain {
      float: right;
    }


  }

  .warning {
    color: $error-color;
  }
}
