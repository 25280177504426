@import 'src/variables';

.edit-outline-content {
  flex-direction: column;

  .content-sidebar {
    display: flex;
    flex-direction: column;

    .editor {
      height: calc(100vh - #{$header-height});
      overflow: hidden;

      .drawing-tool {
        height: calc(100vh - #{$header-height} - #{$title-bar-height});
        //height: 100vh;

        .canvas-container {
          height: calc(100vh - #{$header-height} - #{$title-bar-height});
          //height: 100vh;
        }
      }

      &.onboarding {
        height: calc(100vh - #{$title-bar-height} - #{$suite-toolbar-height});
      }
    }
  }

}