@import "../../variables";

.toggle-icon {

  display: flex;
  justify-content: flex-end;


  .noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  .toggle-icon-button {

    display: flex;
    align-items: center;

    .icon {
      margin: 0;
      display: inline-block;
      height: $icon-button-dimensions;
      width: $icon-button-dimensions;
      cursor: pointer;
    }

    input {
      display: none;
    }
  }

}
