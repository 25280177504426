@import "../../../variables";

.pattern-toggle-switch {
  display: flex;
  align-items: center;
  padding-right: 0.2rem;

  .mirror-button {
    display: none;
  }

  .mirror-label {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: -0.1rem;
  }

  .mirror-icon {
    height: 1.8rem;
    width: 1.8rem;
    cursor: pointer;
  }

  .toggle-switch-label {
    flex-grow: 1;
    margin: 0 0 -.4rem;
  }

  .mirror-button {
    margin-right: 1rem;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
  }

}