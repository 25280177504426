@import "../../variables";
@import "../settings-mixins";

.pattern-styles {

  .pattern-styles-buttons {
    @include style-buttons;

    .pattern-styles-buttons-send {
      button > * {
        width: 6rem;
      }
    }
  }

}