@import "../../../variables";

#LoadingScreen {
  position: fixed;
  left: 0;
  right: 0;
  top: $suite-toolbar-height;
  bottom: 0;
  background: fade_out($primary-color, 0.8);
  display: none;
  z-index: 10;


  #loaderImage {
    width: 31px;
    height: 31px;
  }
}

#LoadingScreen.active {
  display: initial;
}

.LoadingScreen-Panel {
  width: 57px;
  height: 57px;
  left: 50%;
  top: 50%;
  position: absolute;
  margin-left: -28px;
  margin-top: -28px;
  text-align: center;
  padding-top: 13px;
  background: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: -2px 4px 15px 0px rgba(0, 0, 0, 0.43);
}