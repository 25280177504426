@import "../../../../variables";

.xlsx-dxf-uploader {
  .file-input {
    height: 10rem;
  }

  .file-input-title {
    margin-top: .5rem;
    margin-bottom: 2rem;
  }
}