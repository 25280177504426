
.manual-locking-dialog {

  .dialog-window-content {

    width: 28rem;

    .manual-locking-dialog-button-group {
      margin-top: 0 !important;
      flex-direction: column;
      justify-content: space-between !important;

      height: 14rem !important;
    }
  }
}