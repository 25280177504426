@import "../../variables";

.license-dialog {

  .license-dialog-content {
    width: 83.6rem;

    .media-container {
      width: 80rem;
      height: 45rem;
      text-align: center;


    }

    .no-license-warning {
      font-size: 3.6rem;
      font-family: $font-family-condensed;
      text-align: center;
      font-weight: 700;
      padding: 3rem 0 3rem 0;
    }

    .no-license-text {
      font-size: 1.5rem;
      font-family: $font-family;
      text-align: center;

    }

    .license-buttons {

      padding: 2rem 0 0;
      display: block;
      align-items: center;
    }

    .license-button {

      margin: auto;
    }

  }

}