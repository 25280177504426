@import "src/variables";

.plan-panel {
  height: 100%;
  width: 100%;

  &.cursor-pan-grab {
    cursor: url("../../../floorplanner/assets/cursor/CursorPanGrab.svg") 31 31, auto;
  }

  &.cursor-pan-grabbing {
    cursor: url("../../../floorplanner/assets/cursor/CursorPanGrabbing.svg") 31 31, auto;
  }
}

.plan-container {
  height: 100%;
  width: 100%;
}
