@import "../../../../variables";

.reset-button {

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 2.4rem;
  width: 100%;

  .reset-button-button {
    height: 2.4rem;
    width: 6rem;
    border-radius: 1.2rem;
    margin-right: 0;
  }
}
