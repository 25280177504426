@import "../../variables";

.navigation-menu {

  display: flex;
  flex-direction: row;
  height: 100%;

  li {
    display: inline-block;

    .navigation-menu-item-content {

      height: 100%;

      display: inline-block;
      box-sizing: border-box;

      .navigation-menu-item-content-wrapper {
        height: 100%;
        display: flex;
        align-items: center;


        .navigation-menu-item-text-wrapper {
          display: flex;
          align-items: center;
          height: 100%;
          box-sizing: border-box;

          .navigation-menu-item-text {
            display: block;
            text-align: center;

            span {
              letter-spacing: $navigation-bar-letter-spacing;
              vertical-align: calcVerticalAlign($base-font-size);
              line-height: calcLineHeight($base-font-size, $base-line-height);
              margin-top: -0.1rem;

              &::before {
                display: block;
                content: attr(title);
                font-weight: $font-weight-medium;
                height: 1px;
                color: transparent;
                overflow: hidden;
                visibility: hidden;
              }

              &.dark-mode {
                color: white;
              }

            }
          }


        }

        .navigation-menu-item-wrapper-icon {
          height: 1.4rem;
          width: 1.4rem;
          padding-left: .6rem;
          padding-right: 1rem;
        }
      }

    }
  }


  button.navigation-menu-item {
    border: none;
    border-radius: unset;
  }

  > * {
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;
  }

}