@import "../../../variables";

$collapse-button-height: 4.4rem;

#dashboard {

  display: flex;

  width: $dashboard-width;
  height: $content-height;

  border-left: solid 1px $primary-border-color;
  background-color: $primary-background-color;

  .dashboard-scroll-container {

    width: 100%;
    height: calc(#{$content-height});
  }


  .quality-section {

    .label-number-group {
      display: flex;
      flex-direction: column;
      height: 4.7rem;
      margin: 0;

      .title-row {
        margin-bottom: .8rem;
      }

      .number-row {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 4rem;

        .number {
          display: flex;
          flex-direction: row;
        }

        .quality-dot {

          margin-left: 0.7rem;

          width: 0.9rem;
          height: 0.9rem;
          border-radius: 50%;

          &.unfulfilled {
            background-image: linear-gradient(337deg, rgb(248, 92, 79) 106%, rgb(209, 59, 83) -30%);
          }

          &.fulfilled {
            background-image: linear-gradient(330deg, rgb(86, 209, 121) 94%, rgb(29, 191, 176) -2%);
          }

          &.no-value {
            background: rgb(217, 218, 221);
          }

        }
      }

    }

    .quality-bar {
      margin-top: 0;
      padding-top: 1.5rem;
      margin-right: -2rem;
      padding-right: 2rem;
      border-top: solid .1rem $inspector-border-color;

      .quality-bar-title {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .quality-value {
          font-weight: $font-weight-medium;
        }
      }

      .quality-bar-container {

        height: 1.3rem;

        margin-top: .6rem;

        background-size: 1.2rem 1.2rem;
        background-image: linear-gradient(90deg, $primary-border-color 25%, transparent 25%, transparent 50%, $primary-border-color 50%, $primary-border-color 75%, transparent 75%, transparent);

        .quality-bar-indicator {
          height: 100%;
          background-size: 1.2rem 1.2rem;
          background-image: linear-gradient(90deg, rgb(49, 207, 103) 25%, transparent 25%, transparent 50%, rgb(49, 207, 103) 50%, rgb(49, 207, 103) 75%, transparent 75%, transparent);
        }
      }
    }
  }

  .area-section {

    .menu-section {
      padding-top: 1rem;
      padding-bottom: 1rem;

      .area-summary {
        .area-element {
          display: flex;
          align-items: center;
          height: 4.5rem;

          .icon-wrapper {
            margin-top: -2rem;
          }

          .area-info {
            padding-left: .5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 67%;
          }
        }
      }
    }
  }

  .heatmap-section {

    .menu-section > div {
      margin-bottom: 0;

      &:last-child {
        margin-top: 1.2rem;
      }

    }

    .heatmap-button {
      width: 6.6rem;
      height: 4.8rem;
      border-radius: 1px;
      border: solid 1px rgb(217, 218, 221);
      background-color: $primary-background-color;

      svg {
        vertical-align: middle;
      }

      &:first-child {
        margin-right: 1.2rem;
      }
    }
  }

  .optimize-section {

    button {
      width: 100%;
    }

    border-bottom: none;
  }

  .collapse-button {
    position: fixed;
    bottom: 0;
    right: 0;

    width: 18rem;
    height: $collapse-button-height;

    padding-left: 1.8rem;

    border-top: solid 1px $primary-border-color;
    background-color: $primary-background-color;
    text-align: left;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    cursor: pointer;

    span {
      opacity: 0.8;
    }

    svg {

      position: fixed;
      bottom: 1rem;
      right: 1.2rem;

      width: 2.4rem;
      height: 2.4rem;
    }
  }

  > * {
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
    user-select: None;
  }
}