@import "../../../variables";


.builder-nav-bar {

  .builder-nav-bar-content {
    height: $builder-nav-height;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $primary-background-color;
    border-bottom: solid 0.1rem $primary-border-color;
    z-index: 2;
    box-sizing: border-box;

    .builder-nav-back-container {
      display: flex;
      align-items: center;

      padding-left: 0.6rem;
      box-sizing: border-box;

    }

    .builder-nav-navigation-container {
      width: 30rem;
      display: flex;
      justify-content: space-between;
      align-items: center;


      .builder-nav-bar-title-container {

        .builder-nav-bar-title-input {

          width: 25rem;

          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: center;

          border-color: transparent;

          &:focus {
            border-color: $primary-color;
            text-overflow: unset;
            overflow: unset;
            text-align: unset;
          }

        }

      }


    }


    .builder-nav-changes-buttons-container {

      width: $sidebar-width;
      height: 100%;

      display: flex;
      justify-content: flex-end;

      padding-right: $menu-content-padding;
      box-sizing: border-box;

      .builder-nav-changes-buttons {
        display: flex;
        align-items: center;

        button {
          margin: 0;
        }
      }
    }
  }
}


