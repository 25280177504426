@import '../variables';

$advanced-width: 48rem;

.start-app-container {
  display: flex;

  height: calc(100vh - #{$suite-toolbar-height} - #{$title-bar-height});

  .start-app-title {
    margin-bottom: 4rem;
  }

  .start-app-hero {
    margin-bottom: 1.8rem;
  }

  .start-app-copy-text {
    margin-bottom: 3.6rem;
  }

  .start-planing-import-container {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    align-items: center;
    float: left;
  }

  .start-app-button {
    width: 18rem;

    &.planning {

      &:hover {
        background-color: #e7dfb7;
      }
    }
  }

  .start-app-recent-project {
    margin-bottom: 3rem;
    max-width: 360px;
  }

  .start-app-assistant {
    width: calc(100vw - #{$advanced-width});

    padding: 6.4rem 7.2rem;

    background-position: left bottom;
    background-repeat: no-repeat;
    background-image: radial-gradient(circle at 100% 100%, rgba(0, 41, 59, 0), #00293b), url("./assets/image/jpg/StartApp.jpg");
    background-size: 100% auto;

    .start-app-assistant-text {
      width: 31.5rem;
    }
  }

  .start-app-right {
    display: flex;
    flex-direction: column;

    width: $advanced-width;

    .start-app-advanced {
      background-color: #efe9d7;

      padding: 11.7rem 7.2rem 6.4rem;

      &.expand {
        height: 100%;
      }
    }

    .start-app-recent-projects {
      padding-top: 6.4rem;
      height: 100%;

      .start-app-recent-projects-scroll-area {
        height: 100%;

        .start-app-title-container {
          display: flex;

          margin-bottom: -.4rem;
        }

        .start-app-recent-project {
          display: flex;
          justify-content: center;
          padding-left: 7.4rem;
          padding-top: .9rem;
        }

        .recent-project {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-left: 7.4rem;
          height: 5.6rem;

          &:hover {
            background-color: $secondary-background-color;
          }
        }

        .recent-project-icon-container {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-right: 2rem;
          gap: 1.4rem;
        }

        .recent-project-text-container {
          width: 85%;
          margin-right: 2rem;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
          letter-spacing: 0.06rem;

          &.selected {
            width: 78.6%;
          }

          .recent-project-date {
            font-size: 1.4rem;
          }

          .recent-project-text-bold {
            font-weight: 500;
          }

          &.selected {
            background-color: #e8e9eb;
          }
        }

        .recent-project-open {
          padding-right: 2.4rem;
        }

        .recent-project-arrow-icon {
          height: 1.2rem;
          width: 1.2rem;
        }

        .recent-project-border {
          border-top: solid .1rem $inspector-border-color;
        }

        .recent-projects-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }

        .view-all-button {
          border: none;
          display: flex;
          height: 100%;
          font-size: 1.4rem;
        }
      }

    }
  }
}

.delete-project-dialog {
  .delete-button {
    border: solid .1rem #a12a3f;
    background-color: #e51938;
    color: white;
  }
}