@import "../../variables";

$expand-height: 1.3rem;

ul {
  list-style-type: none;
}

.tree-list-container {

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  padding-left: 1.2rem;

  .list-item {

    .list-item-content {

      display: flex;
      align-items: center;
      justify-content: space-between;

      height: $list-items-height;

      font-weight: $font-weight-normal;
      padding-left: 1.2rem;
      padding-right: 0.9rem;

      border-bottom: solid .1rem $inspector-list-divider-color;
      box-sizing: border-box;

      border-left: $department-list-item-colored-border-size;
      border-left-style: solid;
      border-left-color: transparent;


      .list-item-content-icon-text {
        display: flex;
        align-items: center;

        .list-item-icon {
          margin-left: -.5rem;
        }

        .list-item-content-text {
          display: flex;
          justify-content: space-between;

          .list-item-content-label-value {
            margin-right: 1rem;
            width: auto;
            color: $disabled-color,
          }
        }

        .list-item-content-icon {
          height: $icon-button-dimensions !important;
          width: $icon-button-dimensions !important;
          margin-left: -.3rem;
          margin-right: .3rem;
        }
      }

      &.selected {
        font-weight: $font-weight-medium;
      }

      &.selectable {
        cursor: pointer;
      }

      &.disabled-item {
        font-weight: $font-weight-light;
        opacity: 0.3;
      }
    }

    &.collapsible-list-item {
      > .list-item-content {
        padding-left: 2.4rem;
      }
    }

    .list-item {
      margin-left: 1.5rem;
    }

    .list-item-collapsible {

      margin-top: -$list-items-height;

      .list-item-collapsible-trigger {

        display: flex;
        align-items: center;

        cursor: pointer;

        height: $list-items-height;
        width: $icon-button-dimensions;

        margin-left: $department-list-item-colored-border-size;

        .trigger-arrow {
          height: $icon-button-dimensions;
          width: $icon-button-dimensions;

          &.expand {
            margin-bottom: $expand-height * 3;
          }
        }

        &.is-disabled {
          .trigger-arrow {
            opacity: 0.3;
          }
        }

        &.open {
          .trigger-arrow {
            transform: rotate(90deg);
          }
        }

      }
    }
  }

  & > .tree-list > .collapsible-list-item {

    .list-item-content {
      border-bottom: none;
    }

    .list-item:not(.collapsible-list-item) {

      &:last-child {
        .list-item-content {
          border-bottom: none;
        }
      }

      .list-item-content {
        border-bottom: solid 1px $inspector-list-divider-color;
      }
    }

    & > .list-item-collapsible {
      & > .list-item-collapsible-content-container {
        border-bottom: solid 1px $inspector-list-divider-color;
      }
    }

    .list-item-collapsible {
      &.open {
        & > .list-item-collapsible-content-container {
          border-top: solid 1px $inspector-list-divider-color;
        }
      }
    }

    .collapsible-list-item:not(:last-child) {
      & > .list-item-collapsible {
        & > .list-item-collapsible-content-container {
          border-bottom: solid 1px $inspector-list-divider-color;
        }
      }
    }

    &:last-child {
      & > .list-item-collapsible {
        & > .list-item-collapsible-content-container {
          border-bottom: none;
          border-top: solid 1px $inspector-list-divider-color;
        }
      }

      .list-item:not(.collapsible-list-item) {
        &:last-child {
          .list-item-content {
            border-bottom: solid 1px $inspector-list-divider-color;
          }
        }
      }

    }
  }

}

