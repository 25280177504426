.pattern-style {

  .pattern-style-reset-button {
    margin-top: -1rem !important;
  }

  .pattern-style-pattern-picker {
    margin-top: 0.8rem;
  }

  .pattern-style-form-buttons {
    display: flex;
    margin-top: 2rem;
    margin-left: 0.2rem;
    flex-direction: row;

    .pattern-style-customized-button {
      width: 100%;
      margin-right: 0;
    }
  }
}