@import "../../variables";


.list-menu-icons-list-item {

  height: $list-items-height;
  padding-left: $menu-content-padding;

  .list-menu-icons-item-container {

    height: 100%;

    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-right: 0.9rem;
    border-bottom: solid 1px $menu-separator-color;

    .list-menu-icons-item-icon {
      height: 2.4rem;
      width: 2.4rem;
      padding-right: 0.5rem;
    }

    .list-menu-icons-item-title {
      flex-grow: 1;
    }

    .list-menu-icons-item-icons-container {

      display: flex;

      .list-menu-icons-item-icon-button {
        height: 2.4rem;
        width: 2.4rem;
      }

    }


  }

}
