@import "../../variables";

.list-menu {

  display: flex;
  flex-direction: column;

  &.dark-mode {
    background-color: $third-background-color-dark-mode;
    color: $primary-color-dark-mode;
  }

}