@import "../../variables";

.collapsible-menu-section {

  .collapsible-menu-section-trigger-container {

    height: 4.4rem;
    background-color: $secondary-background-color;

    padding-left: 1.8rem;
    box-sizing: border-box;

    &.open {

      border-bottom: solid 1px $primary-border-color;

      .trigger {

        border-bottom: none;

        .trigger-arrow {
          transform: rotate(90deg);
        }
      }
    }

    &.is-disabled {
      color: $disabled-font-color;

      svg {
        opacity: 0.5;
      }
    }

    .trigger {

      height: 100%;
      border-bottom: solid 1px $primary-border-color;
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      align-items: center;

      .trigger-title {
        flex: auto;
        display: flex;
        align-items: center;

        .trigger-icon {
          flex: 0 0 auto;
          padding-right: 0.5rem;
          width: 2.4rem;
          height: 2.4rem;
          vertical-align: middle;
        }

        .trigger-title-text-wrapper {
          flex: auto;

          .trigger-title-text {

          }

        }
      }

      .trigger-arrow {
        flex: 0 0 auto;
        width: 4.5rem;
        height: 4.5rem;
      }

    }
  }

  &.open {

    .collapsible-menu-section-content-container {
      border-bottom: solid 1px $primary-border-color;
    }
  }

}