
.building-edit-dialog {
  input {
    width: 100%;
    margin-bottom: 2rem;
  }

  input[type="file"] {
    width: auto;
    margin-bottom: 0;
  }

  .name-input {
    margin-top: 1rem;
  }
}

