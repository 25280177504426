@import "../../variables";


.lockable-field {

  display: flex;
  align-items: center;

  .lockable-field-item {
    flex: auto;

    &:last-child {
      flex: 0 0 auto;
      margin-right: -0.5rem
    }

  }

}
