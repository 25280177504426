@import '../../variables';

.assistant-dialog {
  height: 22.3rem;
  width: 40rem;

  .dialog-headline {
    color: $secondary-color;
  }

  .dialog-body {
    padding: 0 !important;

    .dialog-content {
      display: flex;

      .assistant-dialog-illustration-container {
        width: 35%;
      }

      .assistant-dialog-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 56%;
        padding: $dialog-window-padding;

        .assistant-dialog-buttons {
          display: flex;
          justify-content: space-between;

          .button {
            margin-right: 0;
          }

          .assistant-dialog-button {
            width: 10.3rem;
          }
        }
      }
    }
  }
}