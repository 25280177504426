$plan-button-group-width: 20rem;

.plan-button-group {
  position: relative;
  z-index: 1;

  bottom: calc(100% - 1.8rem);
  left: calc(50% - #{$plan-button-group-width} / 2);
  width: $plan-button-group-width;

  .plan-button-group-container {
    display: flex;
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}